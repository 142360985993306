<template>
  <div class="game-header">
    <button
      class="back-button"
      @click="goToHome"
    />
    <span class="back-button-text">{{ $t('components.gameHeader.backButton') }}</span>
    <slot name="characters-queue" />
    <button
      v-if="!disableInfoButton"
      class="open-popup-button"
      @click="openPopup"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GameHeader',
  props: {
    disableInfoButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open-popup'],
  methods: {
    goToHome() {
      this.$store.commit('user/reset');
      this.$router.push('/main');
    },
    openPopup() {
      this.$emit('open-popup');
    },
  },
};
</script>

<style scoped>
.game-header{
  display: flex;
  width: 100%;
  padding: 20px 0;
  border: #42b983;
  align-items: center;
}
.back-button{
  width: 77px;
  height: 77px;
  background: url("../assets/media/components/game-header/back-button.svg") no-repeat center/contain;
}
.back-button-text{
  margin: 4px auto 0 11px;
  text-transform: uppercase;
  font-size: 16px;
}
.open-popup-button{
  width: 70px;
  height: 70px;
  background: url("../assets/media/components/game-header/open-popup-button.svg") no-repeat center/contain;
}
</style>
