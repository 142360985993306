<template>
  <div
    class="page"
    :style="{transform: `scale(${scaleValue})`}"
  >
    <v-header
      class="game-header"
      @open-popup="openPopupSpecification"
    >
      <template #characters-queue>
        <v-characters-queue
          v-if="isViewPortMax1024px"
          class="characters-queue"
          :class="{show: !disableGame}"
          v-bind="charactersQueueOptions"
        />
      </template>
      <v-timer
        ref="timer"
        class="timer"
        :time="answersTime"
        @percent-time-remaining="setPercentTimeRemaining"
      />
      <v-score
        ref="score"
        class="score"
      />
    </v-header>
    <div
      class="page-content"
      :class="{show: !disableGame}"
    >
      <v-characters-queue
        v-if="!isViewPortMax1024px"
        class="characters-queue"
        v-bind="charactersQueueOptions"
      />
      <div class="question-box">
        <span
          class="question-box-text"
          v-html="currentSurvey.question.question"
        />
      </div>
      <v-slider-time
        class="slider-timer"
        :translate-x="percentTimeRemaining"
      />
      <v-checkbox-group
        class="checkbox-group"
        :items="currentSurvey.question.answers"
        :enable-states="enableAnswersStates"
        :disable="disableCheckboxGroup"
        @selected="setSelectedAnswers"
      />
      <button
        class="main-button next-button"
        :class="{disable: disableNextButton || disableCheckboxGroup}"
        @click="goToNextSurvey"
      >
        {{ $t('pages.gamePage.nextButton') }}
      </button>
    </div>
    <v-flowerpot-animation class="flowerpot-animation" />
    <v-characters :character="currentCharacter" />
    <v-popup-specification
      v-model:opened="popupSpecificationOpened"
      :specification="currentSpecification"
      :enable-intro="enableIntro"
      :disable-game="disableGame"
      class="popup-specification"
      @start-game="startGame"
    />
    <v-popup-level-losing
      v-model:opened="popupLevelLosingOpened"
      :score-value="currentLevelScoreValue"
      :score-game-value="user.scoreValue"
      :score-guru-pharma-value="scoreGuruPharmaValue"
      class="popup-level-losing"
      @restart-level="restartLevel"
      @restart-game="restartGame"
      @goto-next-level="gotoNextLevel"
    />
    <v-popup-level-success
      v-model:opened="popupLevelSuccessOpened"
      :score-value="currentLevelScoreValue"
      :level-value="remainingLevels"
      class="popup-level-success"
      @goto-next-level="gotoNextLevel"
    />
    <v-popup-notification
      v-model:opened="popupNotificationOpened"
      :text="$t('common.wrongModeText')"
    />
    <v-preload-hidden-images
      :images="[
        require('../assets/media/pages/game/next-button-defalut.svg'),
        require('../assets/media/pages/game/next-button-hover.svg'),
        require('../assets/media/pages/game/next-button-active.svg'),
      ]"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import vTimer from '@/components/Timer.vue';
import vScore from '@/components/Score.vue';
import vHeader from '@/components/GameHeader.vue';
import vSliderTime from '@/components/SliderTime.vue';
import vCheckboxGroup from '@/components/CheckboxGroup.vue';
import vPopupSpecification from '@/components/popups/PopupSpecification.vue';
import vPopupLevelSuccess from '@/components/popups/PopupLevelSuccess.vue';
import vPopupNotification from '@/components/popups/PopupNotification.vue';
import vPopupLevelLosing from '@/components/popups/PopupLevelLosing.vue';
import vFlowerpotAnimation from '@/components/FlowerpotAnimation.vue';
import vCharactersQueue from '@/components/CharactersQueue.vue';
import vCharacters from '@/components/Characters.vue';
import gameScale from '@/mixins/game-scale';
import config from '@/config/common.json';
import ranks from '@/config/game/ranks.json';
import { getRandomItems, isTouchDevice } from '@/utils';

const { SURVEY_COUNT, TIMEOUT_AFTER_NEXT_ANSWERS } = config;

export default {
  name: 'Game',
  components: {
    vHeader,
    vSliderTime,
    vTimer,
    vScore,
    vPopupSpecification,
    vPopupLevelSuccess,
    vPopupNotification,
    vPopupLevelLosing,
    vCharactersQueue,
    vCheckboxGroup,
    vFlowerpotAnimation,
    vCharacters,
  },
  mixins: [gameScale],
  data() {
    return {
      intervalID: null,
      enableIntro: true,
      popupSpecificationOpened: true,
      popupLevelSuccessOpened: false,
      popupNotificationOpened: false,
      popupLevelLosingOpened: false,
      enableAnswersStates: false,
      disableCheckboxGroup: false,
      disableNextButton: false,
      isViewPortMax1024px: true,
      disableGame: true,
      currentLevelIndex: 0,
      currentSurveyIndex: 0,
      currentLevelScoreValue: 0,
      percentTimeRemaining: 100,
      selectedAnswers: [],
      ranks,
    };
  },
  computed: {
    ...mapState(['user', 'levels']),
    currentLevel() {
      return this.levels[`level${this.currentLevelIndex + 1}`];
    },
    surveys() {
      return getRandomItems(this.currentLevel.surveys, SURVEY_COUNT);
    },
    currentSurvey() {
      return this.surveys[this.currentSurveyIndex];
    },
    currentSpecification() {
      return this.currentLevel.specification;
    },
    currentCharacter() {
      if (this.disableGame) return null;
      console.warn(
        'ID:', this.currentSurvey.character.componentID, '\n',
        'ИМЯ:', this.currentSurvey.character.name, '\n',
        'ПРИЗНАКИ:', this.currentSurvey.character.signs,
      );
      return this.currentSurvey.character.componentID;
    },
    answersTime() {
      return this.currentLevel.answersTime;
    },
    charactersQueueOptions() {
      return {
        count: SURVEY_COUNT,
        currentIndex: this.currentSurveyIndex,
      };
    },
    isLastQuestion() {
      return this.currentSurveyIndex + 1 === SURVEY_COUNT;
    },
    maxLevelScoreValue() {
      return this.currentLevel.scoreByQuestion * SURVEY_COUNT;
    },
    remainingLevels() {
      return Object.keys(this.levels).length - this.currentLevelIndex - 1;
    },
    scoreGuruPharmaValue() {
      return this.ranks[this.ranks.length - 1].min;
    },
  },
  watch: {
    selectedAnswers: {
      handler(items) {
        this.disableNextButton = (items.length === 0);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.checkScreenOrientation();
    this.intervalID = setInterval(this.checkScreenOrientation.bind(this), 2000);
  },
  mounted() {
    this.isViewPortMax1024px = window.matchMedia('(max-width: 1024px)').matches;
  },
  beforeUnmount() {
    clearInterval(this.intervalID);
  },
  methods: {
    startGame() {
      this.disableGame = false;
      this.$refs.timer.initTimer();
      this.$refs.timer.startTimer();
      setTimeout(() => {
        this.enableIntro = false;
      }, 300);
    },
    stopGame() {
      this.disableGame = true;
      this.$refs.timer.stopTimer();
    },
    restartLevel() {
      this.$store.dispatch('user/setScore', -this.currentLevelScoreValue);
      this.$refs.score.setScoreValue(this.user.scoreValue);
      this.refreshLevel();
      this.startGame();
    },
    restartGame() {
      this.$store.commit('user/reset');
      this.$refs.score.setScoreValue(this.user.scoreValue);
      this.currentLevelIndex = 0;
      this.refreshLevel();
      this.startGame();
    },
    setPercentTimeRemaining(value) {
      this.percentTimeRemaining = value;
      if (value === 100) this.goToNextSurvey();
    },
    gotoNextLevel() {
      if (this.remainingLevels === 0) {
        this.goToPageConclusion();
        return;
      }
      this.currentLevelIndex += 1;
      this.refreshLevel();
      this.openPopupSpecification();
    },
    goToNextSurvey() {
      this.enableAnswersStates = true;
      this.disableCheckboxGroup = true;

      this.$refs.timer.stopTimer();

      if (this.isCorrectAnswer()) {
        this.$store.dispatch('user/setScore', this.currentLevel.scoreByQuestion);
        this.currentLevelScoreValue += this.currentLevel.scoreByQuestion;
        this.$refs.score.setScoreValue(this.user.scoreValue);
      }

      setTimeout(() => {
        if (this.isLastQuestion) {
          this.stopGame();
          this.showLevelResult();
        } else {
          this.initNextSurvey();
        }
      }, TIMEOUT_AFTER_NEXT_ANSWERS);
    },
    initNextSurvey() {
      this.refreshSurvey();
      this.currentSurveyIndex += 1;
      this.$refs.timer.initTimer();
      this.$refs.timer.startTimer();
    },
    refreshLevel() {
      this.currentLevelScoreValue = 0;
      this.currentSurveyIndex = 0;
      this.refreshSurvey();
    },
    refreshSurvey() {
      this.enableAnswersStates = false;
      this.disableCheckboxGroup = false;
      this.selectedAnswers = [];
    },
    showLevelResult() {
      if (this.currentLevelScoreValue < this.maxLevelScoreValue) {
        this.popupLevelLosingOpened = true;
      } else {
        if (this.remainingLevels === 0) this.goToPageConclusion();
        this.popupLevelSuccessOpened = true;
      }
    },
    isCorrectAnswer() {
      if (!this.selectedAnswers.length) return false;
      if (this.selectedAnswers.some((item) => !item.isRight)) return false;
      return (this.selectedAnswers.length === this.currentSurvey.question.answers.filter((item) => item.isRight).length);
    },
    setSelectedAnswers(items) {
      this.selectedAnswers = items;
    },
    openPopupSpecification() {
      this.popupSpecificationOpened = true;
    },
    goToPageConclusion() {
      this.$router.push('conclusion').catch(() => {
      });
    },
    checkScreenOrientation() {
      if (isTouchDevice()) {
        this.popupNotificationOpened = matchMedia('(orientation: portrait)').matches;
      }
    },
  },
};
</script>

<style scoped>
.page{
  position: relative;
  width: 1920px;
  height: 944px;
  padding: 0 55px;
  background: url("../assets/media/pages/game/bg.svg") no-repeat center/cover;
  transform-origin: center top;
}
.page-content{
  position: relative;
  width: 100%;
  height: 85%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s;
}
.page-content.show{
  pointer-events: auto;
  opacity: 1;
}
.game-header .score{
  margin-left: 25px;
}
.game-header .timer{
  margin-left: 42px;
}
.characters-queue{
  margin: 7px 0 0 7px;
}
.question-box{
  display: flex;
  width: 587px;
  height: 181px;
  padding: 0 55px;
  margin-top: 30px;
  background: url("../assets/media/pages/game/qurstion-box.svg") no-repeat center/contain;
}
.question-box-text{
  font: 20px/25px "Dela Gothic One";
  margin: auto;
}
.slider-timer{
  margin-top: 15px;
}
.checkbox-group{
  margin-top: 40px;
}
.next-button{
  width: 320px;
  height: 98px;
  margin-top: 20px;
  background: url("../assets/media/pages/game/next-button-defalut.svg") no-repeat center/contain;
  user-select: none;
}
.next-button:hover{
  background: url("../assets/media/pages/game/next-button-hover.svg") no-repeat center/contain;
}
.next-button:active{
  background: url("../assets/media/pages/game/next-button-active.svg") no-repeat center/contain;
}
.next-button.disable{
  background: url("../assets/media/pages/game/next-button-inactive.svg") no-repeat center/contain;
  pointer-events: none;
  opacity: 0.6;
}
.flowerpot-animation{
  top: 51%;
  left: 47%;
}
@media (max-width: 1024px){
  .game-header::v-deep(.open-popup-button){
    width: 80px;
    height: 80px;
  }
  .game-header::v-deep(.back-button-text){
    font-size: 18px;
    margin: 4px 30px 0 11px;
  }
  .game-header::v-deep(.characters-queue){
    padding: 2px 2px 2px 16px;
    margin: 0 auto 0 0;
    pointer-events: none;
    opacity: 0;
  }
  .game-header::v-deep(.characters-queue).show{
    pointer-events: auto;
    opacity: 1;
  }
  .game-header ::v-deep(.characters-queue) .title{
    font-size: 16px;
  }
  .game-header::v-deep(.characters-queue) .number{
    font-size: 22px;
  }
  .timer{
    width: 200px;
    height: 90px;
  }
  .timer::v-deep(.title){
    margin: 22px 0 0 28px;
    font-size: 18px;
  }
  .timer::v-deep(.text){
    margin: 0 0 0 28px;
  }
  .score{
    width: 200px;
    height: 90px;
  }
  .score::v-deep(.title){
    margin: 22px 0 0 28px;
    font-size: 18px;
  }
  .score::v-deep(.text){
    margin: 0 0 0 28px;
  }
  .question-box{
    margin-top: 0;
    width: 720px;
    height: 223px;
    padding: 0 65px;
  }
  .question-box .question-box-text{
    font-size: 26px;
    line-height: 30px;
  }
  .slider-timer{
    width: 660px;
  }
  .slider-timer::v-deep(.title){
    font-size: 18px;
  }
  .checkbox-group{
    width: 660px;
  }
  .checkbox-group::v-deep(.checkbox-label::before){
    width: 30px;
    height: 30px;
  }
  .checkbox-group::v-deep(.checkbox-label){
    font-size: 24px;
    line-height: 28px;
  }
  .main-button{
    font-size: 30px;
  }
  .popup-level-losing::v-deep(.text1),
  .popup-level-losing::v-deep(.text2),
  .popup-level-losing::v-deep(.text3),
  .popup-level-losing::v-deep(.score-value-description){
    font-size: 26px;
    line-height: 32px;
  }
  .popup-level-losing::v-deep(.score-value){
    font-size: 46px;
  }
  .popup-level-success::v-deep(.text1),
  .popup-level-success::v-deep(.score-value-description),
  .popup-level-success::v-deep(.text2 span){
    font-size: 26px;
    line-height: 32px;
  }
  .popup-level-losing::v-deep(.score-value){
    font-size: 46px;
  }
  .popup-specification{
    padding: 0 75px 140px 45px;
  }
  .popup-specification::v-deep(.list){
    margin: 30px 0;
  }
  .popup-specification::v-deep(.specification-text){
    font-size: 24px;
    line-height: 26px;
  }
}
</style>
