<template>
  <v-main-header v-if="enableMainHeader" />
  <router-view />
</template>

<script>
import vMainHeader from '@/components/MainHeader.vue';

export default {
  name: 'App',
  components: {
    vMainHeader,
  },
  data() {
    return {
      enableMainHeader: false,
    };
  },
  created() {
    this.$router.beforeEach((to) => {
      this.enableMainHeader = to.path === '/main';
    });
  },
};
</script>

<style> @import "~normalize.css";</style>
<style src="./assets/styles/imports.css"/>
<style></style>
