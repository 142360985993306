<template>
  <div class="characters-queue">
    <span class="title">  {{ $t('components.charactersQueue.title') }}</span>
    <div
      v-for="(item, index) in count"
      :key="`item-${item}`"
      class="item"
    >
      <span
        class="number"
        :class="{active: currentIndex === index}"
      >{{ item }}</span>
      <div
        v-show="currentIndex === index && currentIndex !== count -1"
        class="arrow"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CharactersQueue',
  props: {
    count: {
      type: Number,
      default: 6,
    },
    currentIndex: {
      type: Number,
      default: 5,
    },
  },
};
</script>

<style scoped>
.characters-queue{
  display: inline-flex;
  align-items: center;
  height: 43px;
  padding: 0 2px 0 16px;
  border-radius: 10px;
  border: 2px solid var(--secondary-color-5);
  background: #ffffff;
}
.title{
  margin-right: 19px;
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
}
.item{
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.number{
  color: var(--secondary-color-4);
}
.number.active{
  padding: 4px 9px 3px 9px;
  border-radius: 9px;
  font-weight: 700;
  color: var(--primary-color);
  background: var(--secondary-color-5);
}
.arrow{
  width: 17px;
  height: 10px;
  margin: 0 0 0 10px;
  background: url("../assets/media/components/characters-queue/arrow.svg") no-repeat center/contain;
}
</style>
