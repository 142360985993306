<template>
  <div
    class="popup popup-socials-sharing"
    :class="{opened}"
    @transitionend="hideContent"
  >
    <div
      v-show="shownContent"
      class="popup-content"
    >
      <div class="border-block">
        <div
          class="socials-wrapper"
        >
          <h3 class="title">
            {{ $t('components.popupSocialsSharing.socialsTitle') }}
          </h3>
          <div class="socials">
            <a
              :href="shareLinkVK"
              target="_blank"
              class="social vk"
            >
              <div class="social-icon vk" />
              {{ $t('components.popupSocialsSharing.vk') }}
            </a>
          </div>
          <p class="share-text1">
            <span>{{ $t('components.popupSocialsSharing.shareText1') }}</span>
            <span class="score-value"> {{ scoreValue }} </span>
            <span class="score-value-description"> {{ scoreValueDescription }} </span>
          </p>
          <p class="share-text2">
            <span class="rank-text-description"> {{ $t('components.popupSocialsSharing.shareText2') }}</span>
            <span class="rank-text">"{{ rankText }}"</span>
          </p>
          <button
            class="main-button close-popup-button"
            @click="closePopup"
          >
            {{ $t('components.popupSocialsSharing.closeButtonTitle') }}
          </button>
        </div>
      </div>
    </div>
    <v-preload-hidden-images
      :images="[
        require('../../assets/media/components/popup-socials-sharing/close-button-hover.svg'),
        require('../../assets/media/components/popup-socials-sharing/close-button-active.svg'),
      ]"
    />
  </div>
</template>

<script>
import { numberEnd } from '@/utils';

export default {
  name: 'PopupSocialsSharing',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    scoreValue: {
      type: Number,
      default: 0,
    },
    rankText: {
      type: String,
      default: '',
    },
    rankIndex: {
      type: Number,
      default: 0,
    },
    socialsOptions: {
      type: Object,
      default: () => ({
        share_url: '',
        images: {},
        socials: {
          vk: {},
        },
      }),
    },
  },
  emits: ['update:opened'],
  data() {
    return {
      shownContent: false,
    };
  },
  computed: {
    scoreValueDescription() {
      return numberEnd(this.scoreValue, [
        this.$t('components.popupSocialsSharing.scoreTextType1'),
        this.$t('components.popupSocialsSharing.scoreTextType2'),
        this.$t('components.popupSocialsSharing.scoreTextType3'),
      ]);
    },
    scoreDescription() {
      return `${this.$t('components.popupSocialsSharing.shareText3')} ${this.scoreValue} ${this.$t(this.scoreValueDescription)}`;
    },
    rankDescription() {
      return this.$t('components.popupSocialsSharing.shareText4') + this.rankText;
    },
    shareText() {
      return encodeURI(`${this.scoreDescription}. ${this.rankDescription}. ${this.$t('components.popupSocialsSharing.shareText5')}`);
    },
    shareLinkVK() {
      return [
        `http://vk.com/share.php?url=${this.socialsOptions.share_url}`,
        `&title=${this.shareText}`,
        `&description=${this.shareText}`,
        `&image=${this.socialsOptions.rankImages[this.rankIndex]}`,
        `&noparse=${true}`,
      ].join('');
    },
  },
  watch: {
    opened: {
      immediate: true,
      handler(value) {
        if (value) this.showContent();
      },
    },
  },
  methods: {
    closePopup() {
      this.$emit('update:opened', false);
    },
    showContent() {
      this.shownContent = true;
    },
    hideContent() {
      if (!this.opened) this.shownContent = false;
    },
  },
};
</script>

<style scoped>
.popup-content{
  padding: 20px;
}
.border-block{
  padding: 0 25px 10px 25px;
  border: 4px solid var(--secondary-color-5);
}
.socials-wrapper{
  width: 100%;
}
.title{
  margin: 25px 0;
  text-align: center;
}
.socials{
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin-top: 10px;
}
.social{
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 150px;
}
.social:link,
.social:active,
.social:hover,
.social:visited{
  color: var(--primary-color);
}
.social-icon{
  width: 100px;
  height: 100px;
}
.social-icon.vk{
  background: url("../../assets/media/components/popup-socials-sharing/icon-vk.png") no-repeat center/contain;
}
.share-text1,
.share-text2{
  margin-top: 16px;
  font-size: 18px;
  text-align: center;
}
.share-text2{
  margin: 0 0 15px 0;
}
.score-value,
.rank-text{
  font-size: 25px;
  font-weight: 700;
  margin-left: 5px;
}
.score-value-description,
.rank-text-description{
  margin-left: 10px;
}
.close-popup-button{
  width: 350px;
  height: 100px;
  margin: 30px auto 10px;
  background: url("../../assets/media/components/popup-socials-sharing/close-button-default.svg") no-repeat center/contain;
}
.close-popup-button:hover{
  background: url("../../assets/media/components/popup-socials-sharing/close-button-hover.svg") no-repeat center/contain;
}
.close-popup-button:active{
  background: url("../../assets/media/components/popup-socials-sharing/close-button-active.svg") no-repeat center/contain;
}
</style>
