import levels from './levels.json';
import characters from './characters.json';
import questions from './questions.json';

Object
  .values(levels)
  .forEach((level) => level.surveys.forEach((item) => {
    mapCharacters(item);
    mapQuestion(item);
  }));

/* eslint-disable */

function mapCharacters(item) {
  if (item.characterID) {
    item.character = characters[item.characterID];
    delete item.characterID;
  }
  return item;
}

function mapQuestion(item) {
  if (item.questionID) {
    item.question = questions[item.questionID];
    item.question.answers = item.question.answers.sort((a, b) => 0.5 - Math.random());
    delete item.questionID;
  }
  return item;
}

/* eslint-enable */

export {
  levels,
};
