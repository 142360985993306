<template>
  <div
    class="checkbox-wrapper"
    :class="[selected, state]"
    @click="toggleState"
  >
    <div class="checkbox-label-wrapper">
      <span class="checkbox-label">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: {
      type: String,
      default: 'Checkbox label',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    correctOption: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    selected() {
      return this.checked ? 'selected' : '';
    },
    state() {
      if (!this.showState) return '';
      return this.correctOption ? 'right' : 'wrong';
    },
  },
  methods: {
    toggleState() {
      this.checked = !this.checked;
      this.$emit('change', this.checked);
    },
    isChecked() {
      return this.checked;
    },
    refresh() {
      this.checked = false;
    },
  },
};
</script>

<style scoped>
.checkbox-wrapper{
  border-radius: 60px;
  padding: 15px 25px;
  margin: 10px 0;
  border: 10px solid #dee8ff;
  background: #ffffff;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.checkbox-wrapper:hover{
  background: transparent;
}
.checkbox-wrapper.right{
  background: #50a050;
}
.checkbox-wrapper.selected{
  background: #8baeff;
}
.checkbox-wrapper.selected.right{
  background: #5fbe5f;
}
.checkbox-wrapper.selected.wrong{
  background: #fd544c;
}
.checkbox-label-wrapper{
  position: relative;
  display: flex;
  cursor: pointer;
}
.checkbox-label{
  position: relative;
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-weight: 700;
  font-size: 18px;
}
.checkbox-label::before{
  content: "";
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  background: url("../assets/media/components/checkbox/checkbox.svg") no-repeat center/contain;
}
.selected .checkbox-label::before{
  background: url("../assets/media/components/checkbox/checkbox-checked.svg") no-repeat center/contain;
}
.selected .checkbox-label{
  color: #ffffff;
}
</style>
