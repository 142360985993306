/* eslint-disable global-require */

export default {
  share_url: `${process.env.VUE_APP_BASE_URL}`,
  rankImages: [
    `${process.env.VUE_APP_BASE_URL}/${require('../assets/media/pages/conclusion/rank1-icon.png')}`,
    `${process.env.VUE_APP_BASE_URL}/${require('../assets/media/pages/conclusion/rank2-icon.png')}`,
    `${process.env.VUE_APP_BASE_URL}/${require('../assets/media/pages/conclusion/rank3-icon.png')}`,
    `${process.env.VUE_APP_BASE_URL}/${require('../assets/media/pages/conclusion/rank4-icon.png')}`,
    `${process.env.VUE_APP_BASE_URL}/${require('../assets/media/pages/conclusion/rank5-icon.png')}`,
  ],
};
