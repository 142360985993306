<template>
  <div
    class="checkbox-group"
    :class="{disable}"
  >
    <div class="item-wrapper">
      <v-checkbox
        v-for="(item, index) in items"
        :key="index"
        :ref="setItemRef"
        :label="item.text"
        :show-state="enableStates"
        :correct-option="item.isRight"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import vCheckbox from './Checkbox.vue';

export default {
  name: 'CheckboxGroup',
  components: {
    vCheckbox,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [
          { text: 'Value 1', isRight: false },
          { text: 'Value 2', isRight: true },
        ];
      },
    },
    enableStates: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selected'],
  data() {
    return {
      selectedItems: [],
      checkboxesRefs: [],
    };
  },
  watch: {
    items: {
      handler() {
        this.checkboxesRefs.forEach((component) => component.refresh());
      },
      deep: true,
    },
  },
  beforeUpdate() {
    this.checkboxesRefs = [];
  },
  methods: {
    setItemRef(element) {
      if (element) this.checkboxesRefs.push(element);
    },
    onChange() {
      this.selectedItems = this.checkboxesRefs.reduce((selectedItems, component, index) => {
        if (component.isChecked()) selectedItems.push(this.items[index]);
        return selectedItems;
      }, []);
      this.$emit('selected', this.selectedItems);
    },
  },
};
</script>

<style scoped>
.checkbox-group{
  width: 580px;
}
.checkbox-group.disable{
  opacity: 0.9;
  pointer-events: none;
}
</style>
