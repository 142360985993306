<template>
  <div
    class="page"
    :style="{transform: `scale(${scaleValue})`}"
  >
    <v-game-header
      class="game-header"
      disable-info-button
    >
      <v-score
        ref="score"
        class="score"
      />
    </v-game-header>
    <div
      v-show="!shownSuccessState"
      class="form-wrapper"
    >
      <h2 class="title">
        {{ $t('pages.promoCodePage.getPromo') }}
      </h2>
      <v-promo-code-form
        class="promo-code-form"
        @submitted="submittedHandler"
      />
      <div
        class="promo-info"
        @click="openPopupAboutPromoCode"
      >
        <img
          src="../assets/media/pages/promo-code/promo-code-info.svg"
          alt="Promo code Info"
        >
        <p>{{ $t('pages.promoCodePage.aboutPromo') }}</p>
      </div>
    </div>
    <div
      class="success-wrapper"
      :class="{show: shownSuccessState}"
    >
      <div class="success-popup">
        <img
          src="../assets/media/pages/promo-code/promo-code-success.svg"
          alt=""
        >
        <span>{{ $t('pages.promoCodePage.promoSuccess') }}</span>
      </div>
      <div class="restart-button-wrapper">
        <button
          class="restart-button"
          @click="restartGame"
        />
        <span class="restart-button-title"> {{ $t('pages.promoCodePage.restartButtonTitle') }}</span>
      </div>
      <div class="rank-box">
        <img
          class="rank-image"
          :src="rankImage"
          alt="rank-image"
        >
        <div class="left-column">
          <p class="rank-text">
            {{ rankText }}
          </p>
          <div class="share-button-wrapper">
            <button
              class="share-button"
              @click="shareResult"
            />
            <span class="share-text">{{ $t('pages.conclusion.shareText') }}</span>
          </div>
        </div>
      </div>
    </div>
    <v-flowerpot-animation class="flowerpot-animation" />
    <v-popup-about-promo-code
      v-model:opened="popupAboutPromoCodeOpened"
      class="popup-about-promo-code"
    />
    <v-popup-socials-sharing
      v-model:opened="isPopupSocialsOpened"
      :score-value="user.scoreValue"
      :rank-text="rankText"
      :rank-index="user.rankIndex"
      :socials-options="socialsConfig"
      class="popup-socials-sharing"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import vPopupAboutPromoCode from '@/components/popups/PopupAboutPromoCode.vue';
import vPopupSocialsSharing from '@/components/popups/PopupSocialsSharing.vue';
import vFlowerpotAnimation from '@/components/FlowerpotAnimation.vue';
import vPromoCodeForm from '@/components/PromoCodeForm.vue';
import vGameHeader from '@/components/GameHeader.vue';
import vScore from '@/components/Score.vue';
import gameScale from '@/mixins/game-scale';
import rank1Icon from '@/assets/media/pages/conclusion/rank1-icon.svg';
import rank2Icon from '@/assets/media/pages/conclusion/rank2-icon.svg';
import rank3Icon from '@/assets/media/pages/conclusion/rank3-icon.svg';
import rank4Icon from '@/assets/media/pages/conclusion/rank4-icon.svg';
import rank5Icon from '@/assets/media/pages/conclusion/rank5-icon.svg';
import socialsConfig from '@/config/socials-sharing';
import ranks from '@/config/game/ranks.json';
import User from '@/api/User';

const user = new User();

export default {
  name: 'PromoCode',
  components: {
    vPopupAboutPromoCode,
    vPopupSocialsSharing,
    vFlowerpotAnimation,
    vPromoCodeForm,
    vGameHeader,
    vScore,
  },
  mixins: [gameScale],
  data() {
    return {
      shownSuccessState: false,
      popupAboutPromoCodeOpened: false,
      isPopupSocialsOpened: false,
      socialsConfig,
    };
  },
  computed: {
    ...mapState(['user', 'levels']),
    rankImage() {
      const rankIcons = [
        rank1Icon,
        rank2Icon,
        rank3Icon,
        rank4Icon,
        rank5Icon,
      ];
      return rankIcons[this.user.rankIndex];
    },
    rankText() {
      return ranks[this.user.rankIndex].name;
    },
  },
  mounted() {
    this.$refs.score.setScoreValue(this.user.scoreValue);
  },
  methods: {
    openPopupAboutPromoCode() {
      this.popupAboutPromoCodeOpened = true;
    },
    submittedHandler(event) {
      user.axios
        .put('/add-user', {
          name: event.name,
          phone: event.phone,
          email: event.email,
        })
        .then((response) => {
          this.shownSuccessState = true;
        })
        .catch((error) => {
          console.log(error);
        });
      // user.axios
      //   .post('/send-email', {
      //     email: event.email,
      //   })
      //   .then((response) => {
      //     this.shownSuccessState = true;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    shareResult() {
      this.isPopupSocialsOpened = true;
    },
    restartGame() {
      this.$store.commit('user/reset');
      this.goToPageGame();
    },
    goToPageGame() {
      this.$router.push('game').catch(() => {
      });
    },
  },
};
</script>

<style scoped>
.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 1920px;
  height: 944px;
  padding: 0 55px;
  background: url("../assets/media/pages/promo-code/bg.svg") center/cover no-repeat;
  transform-origin: center top;
}
.game-header .score{
  margin-left: 25px;
}
.form-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  margin: 5px 0 10px 0;
}
.promo-info{
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}
.promo-info p{
  margin-left: 20px;
  font-weight: 800;
}
.flowerpot-animation{
  bottom: 5%;
  left: 15%;
}
.success-wrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}
.success-wrapper.show{
  pointer-events: auto;
  position: static;
  opacity: 1;
}
.success-popup{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 616px;
  height: 250px;
  margin-top: 30px;
  padding: 0 100px;
  background: url("../assets/media/pages/promo-code/success-bg.svg") center/contain no-repeat;
}
.success-popup span{
  margin-left: 30px;
  font: 24px/32px "Montserrat", Arial, sans-serif;
}
.rank-box{
  display: flex;
  align-items: center;
  width: 678px;
  height: 317px;
  padding: 0 20px;
  margin: 38px auto 0;
  background: url("../assets/media/pages/conclusion/conclusion-box.svg") no-repeat center/contain;
}
.rank-image{
  width: 267px;
  height: 217px;
}
.left-column{
  width: 58%;
}
.rank-text{
  font: 28px/24px "Dela Gothic One", Arial, sans-serif;
  color: var(--secondary-color-3);
}
.share-button-wrapper{
  display: flex;
  align-items: center;
  margin-top: 27px;
}
.share-button{
  width: 77px;
  height: 77px;
  margin-right: 6px;
  background: url("../assets/media/pages/conclusion/share-button.svg") no-repeat center/contain;
}
.share-button:hover{
  opacity: 0.9;
}
.share-text{
  font: 28px/27px "Dela Gothic One", Arial, sans-serif;
}
.restart-button-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.restart-button{
  width: 77px;
  height: 77px;
  margin-right: 15px;
  background: url("../assets/media/common/restart-button.svg") no-repeat center/contain;
}
.restart-button-title{
  font: 28px/27px "Dela Gothic One", Arial, sans-serif;
}
@media (max-width: 1024px){
  .page{
    padding: 20px 30px 0;
    width: 100%;
    height: 1150px;
    min-height: 764px;
    overflow: unset;
    transform: unset !important;
  }
  .page .popup{
    position: absolute;
  }
  .page .popup::v-deep(.popup-content){
    top: 100px;
    transform: translate(-50%, 0%);
  }
  .game-header{
    padding: 0 0 10px 0;
  }
  .game-header ::v-deep(.back-button){
    width: 55px;
    height: 55px;
  }
  .game-header ::v-deep(.back-button-text){
    font-size: 14px;
    margin: 0 auto 0 11px;
  }
  .game-header ::v-deep(.score-wrapper){
    width: 160px;
    height: 60px;
  }
  .game-header ::v-deep(.title){
    font-size: 12px;
    margin: 14px 0 0 19px;
  }
  .game-header ::v-deep(.text){
    font-size: 14px;
  }
  .title{
    font-size: 36px;
  }
  .promo-code-form{
    width: 480px;
    height: 515px;
  }
  .promo-code-form::v-deep(.label){
    width: 400px;
    margin-left: 5px;
  }
  .promo-code-form::v-deep(.label span){
    font-size: 11px;
  }
  .promo-code-form::v-deep(.label input){
    padding: 16px 20px;
    font-size: 14px;
  }
  .promo-code-form::v-deep(.checkbox-label){
    width: 400px;
    margin: 5px 0 15px;
  }
  .promo-code-form::v-deep(.submit-button){
    width: 280px;
    height: 78px;
    font-size: 24px;
  }
  .flowerpot-animation{
    width: 180px;
    flex-shrink: 0;
    position: static;
    transform: scale(0.8);
  }
  .popup-about-promo-code::v-deep(.popup-content){
    width: 590px;
  }
  .popup-about-promo-code::v-deep(.close-button){
    top: -22px;
    right: -22px;
    width: 60px;
    height: 60px;
  }
  .popup-about-promo-code::v-deep(.title){
    font-size: 36px;
  }
  .popup-about-promo-code::v-deep(.text),
  .popup-about-promo-code::v-deep(.text a){
    font-size: 16px;
    line-height: 18px;
  }
  .popup-about-promo-code::v-deep(.promo-image){
    width: 440px;
    height: auto;
  }
  .success-popup{
    width: 480px;
    height: 160px;
    margin-top: 70px;
  }
  .success-popup img{
    width: 60px;
    height: 60px;
  }
  .success-popup span{
    font-size: 16px;
    line-height: 20px;
  }
  .restart-button{
    width: 55px;
    height: 55px;
  }
  .restart-button-title{
    font-size: 18px;
  }
  .rank-box{
    width: 460px;
    height: 217px;
    margin: 70px auto 25px;
  }
  .rank-box .rank-text{
    font-size: 18px;
  }
  .rank-box .share-button{
    width: 55px;
    height: 55px;
  }
  .rank-box .share-text{
    font-size: 18px;
  }
  .rank-box .rank-image{
    width: 187px;
    height: auto;
  }
  .rank-box .share-button-wrapper{
    margin-top: 10px;
  }
  .popup-socials-sharing::v-deep(.popup-content){
    width: 620px;
  }
  .popup-socials-sharing::v-deep(.title){
    margin: 15px 0;
    font-size: 24px;
    line-height: 30px;
  }
  .popup-socials-sharing::v-deep(.socials){
    height: 100px;
  }
  .popup-socials-sharing::v-deep(.social-icon){
    width: 60px;
    height: 60px;
  }
  .popup-socials-sharing::v-deep(.social.vk){
    font-size: 22px;
  }
  .popup-socials-sharing::v-deep(.share-text1 span),
  .popup-socials-sharing::v-deep(.share-text2 span){
    font-size: 16px;
  }
  .popup-socials-sharing::v-deep(span.score-value),
  .popup-socials-sharing::v-deep(span.rank-text){
    font-size: 22px;
  }
  .popup-socials-sharing::v-deep(.close-popup-button){
    width: 300px;
    height: 78px;
    margin: 10px auto 10px;
    font-size: 24px;
  }
}
@media (max-width: 650px){
  .page{
    padding: 20px 20px 0;
    height: 1050px;
  }
  .title{
    font-size: 24px;
  }
  .promo-code-form{
    width: 400px;
    height: 425px;
  }
  .promo-code-form::v-deep(.label){
    width: 330px;
    margin-bottom: 10px;
  }
  .promo-code-form::v-deep(.label span){
    font-size: 10px;
    margin-bottom: 2px;
  }
  .promo-code-form::v-deep(.label input){
    padding: 10px 20px;
    font-size: 12px;
  }
  .promo-code-form::v-deep(.checkbox-label){
    width: 330px;
    margin: 5px 0 15px;
  }
  .promo-code-form::v-deep(.checkbox-label::before){
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .promo-code-form::v-deep(.check-icon){
    top: 4px;
    left: 3px;
    width: 15px;
    height: 14px;
  }
  .promo-code-form::v-deep(.checkbox-label span){
    font-size: 11px;
    line-height: 14px;
  }
  .promo-code-form::v-deep(.submit-button){
    width: 190px;
    height: 58px;
    font-size: 16px;
  }
  .promo-info img{
    width: 60px;
  }
  .promo-info img:hover{
    opacity: 0.95;
  }
  .promo-info p{
    font-size: 22px;
    line-height: 24px;
  }
  .popup-about-promo-code::v-deep(.popup-content){
    width: 410px;
    padding: 0 40px 40px 30px;
  }
  .popup-about-promo-code::v-deep(.close-button){
    top: -18px;
    right: -18px;
    width: 50px;
    height: 50px;
  }
  .popup-about-promo-code::v-deep(.title){
    font-size: 24px;
    margin: 30px 0 0 20px;
  }
  .popup-about-promo-code::v-deep(.text),
  .popup-about-promo-code::v-deep(.text a){
    font-size: 14px;
    line-height: 16px;
  }
  .popup-about-promo-code::v-deep(.promo-image){
    width: 340px;
    margin: 20px auto 0;
  }
  .success-popup{
    width: 400px;
    height: 160px;
    padding: 0 70px;
  }
  .rank-box{
    width: 400px;
    height: 187px;
  }
  .rank-box .rank-image{
    width: 147px;
    height: auto;
    margin: 70px auto 60px;
  }
  .popup-socials-sharing::v-deep(.popup-content){
    width: 410px;
  }
  .popup-socials-sharing::v-deep(.border-block){
    padding: 0 5px 10px 5px;
  }
}
@media (max-width: 450px){
  .page{
    height: 970px;
  }
  .game-header ::v-deep(.back-button){
    width: 45px;
    height: 45px;
  }
  .game-header ::v-deep(.back-button-text){
    font-size: 12px;
    margin: 0 auto 0 11px;
  }
  .game-header ::v-deep(.score-wrapper){
    width: 110px;
    height: 45px;
  }
  .game-header ::v-deep(.title){
    font-size: 10px;
    line-height: 1.2;
    margin: 10px 0 0 19px;
  }
  .game-header ::v-deep(.text){
    font-size: 11px;
    line-height: 1.2;
  }
  .title{
    font-size: 24px;
  }
  .promo-code-form{
    width: 360px;
    height: 395px;
  }
  .promo-code-form::v-deep(.label){
    width: 310px;
    margin-left: 5px;
  }
  .promo-code-form::v-deep(.label span){
    font-size: 10px;
  }
  .promo-code-form::v-deep(.label input){
    padding: 10px 20px;
    font-size: 12px;
  }
  .promo-code-form::v-deep(.checkbox-label){
    width: 290px;
    margin: 3px 0 7px;
  }
  .promo-code-form::v-deep(.checkbox-label span){
    font-size: 10px;
    line-height: 12px;
  }
  .promo-code-form::v-deep(.submit-button){
    width: 170px;
    height: 51px;
    padding-bottom: 8px;
  }
  .promo-info p{
    font-size: 18px;
    line-height: 20px;
  }
  .flowerpot-animation{
    transform: scale(0.6);
  }
  .popup-about-promo-code::v-deep(.popup-content){
    width: 340px;
    padding: 0 30px 40px 20px;
  }
  .popup-about-promo-code::v-deep(.close-button){
    top: -12px;
    right: -12px;
    width: 40px;
    height: 40px;
  }
  .popup-about-promo-code::v-deep(.title){
    font-size: 24px;
  }
  .popup-about-promo-code::v-deep(.text),
  .popup-about-promo-code::v-deep(.text a){
    font-size: 14px;
    line-height: 16px;
  }
  .popup-about-promo-code::v-deep(.promo-image){
    width: 270px;
    height: auto;
  }
  .success-popup{
    width: 360px;
    height: 130px;
    margin-top: 70px;
    padding: 0 50px;
  }
  .success-popup img{
    width: 50px;
    height: 50px;
  }
  .success-popup span{
    font-size: 14px;
    line-height: 18px;
  }
  .restart-button{
    width: 45px;
    height: 45px;
  }
  .restart-button-title{
    font-size: 16px;
  }
  .rank-box{
    width: 340px;
    height: 157px;
    margin: 70px auto 0;
  }
  .rank-box .rank-text{
    font-size: 16px;
  }
  .rank-box .share-button{
    width: 45px;
    height: 45px;
  }
  .rank-box .share-text{
    font-size: 16px;
  }
  .rank-box .rank-image{
    width: 127px;
    height: auto;
  }
  .popup-socials-sharing::v-deep(.popup-content){
    width: 340px;
  }
  .popup-socials-sharing::v-deep(.title){
    margin: 10px 0;
    font-size: 18px;
    line-height: 22px;
  }
  .popup-socials-sharing::v-deep(.socials){
    height: 70px;
  }
  .popup-socials-sharing::v-deep(.social-icon){
    width: 50px;
    height: 50px;
  }
  .popup-socials-sharing::v-deep(.social.vk){
    font-size: 18px;
  }
  .popup-socials-sharing::v-deep(.share-text1 span),
  .popup-socials-sharing::v-deep(.share-text2 span){
    font-size: 14px;
  }
  .popup-socials-sharing::v-deep(span.score-value),
  .popup-socials-sharing::v-deep(span.rank-text){
    font-size: 18px;
  }
  .popup-socials-sharing::v-deep(.close-popup-button){
    width: 260px;
    height: 68px;
    font-size: 18px;
  }
}
</style>
