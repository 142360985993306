<template>
  <div
    v-if="animate"
    class="character"
    :class="{animate, hide}"
    @transitionend="onTransitionEnd"
  >
    <div class="body" />
    <div class="head" />
  </div>
</template>

<script>
import mixin from './template/mixin';

export default {
  name: 'Man1Type1',
  mixins: [mixin],
};
</script>

<style scoped>
@import "./template/base.css";
.character{
  top: 366px;
  right: 0;
  width: 270px;
  height: 490px;
  transform: scale(1.3) translateX(120%);
}
.character.animate{
  animation: showCharacter var(--transition-duration) var(--transition-delay) forwards;
}
.body{
  position: absolute;
  top: 0;
  left: -30px;
  width: 285px;
  height: 488px;
  background: url("../../assets/media/components/characters/man-1-type-1/body.svg") no-repeat center/contain;
}
.head{
  position: absolute;
  top: -102px;
  left: 59px;
  width: 135px;
  height: 142px;
  background: url("../../assets/media/components/characters/man-1-type-1/head.svg") no-repeat center/contain;
  transform-origin: bottom;
  animation: rotateInLeft 7s 0.5s infinite;
}
@keyframes showCharacter{
  0%{
    transform: translateX(120%) scale(1.3);
  }
  100%{
    transform: translateX(-60%) scale(1.3);
  }
}
@keyframes rotateInLeft{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0);
  }
}
</style>
