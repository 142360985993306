<template>
  <div class="preload-hide-images">
    <img
      v-for="(src, index) in images"
      :key="`image${index}`"
      :src="src"
      class="preload-hide-image"
      alt="preload-hide-image"
      rel="preload"
    >
  </div>
</template>

<script>
export default {
  name: 'PreloadHiddenImages',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.preload-hide-images{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
.preload-hide-image{
  width: 1px;
  height: 1px;
}
</style>
