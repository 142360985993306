<template>
  <div
    class="popup popup-about-promo-code"
    :class="{opened}"
    @transitionend="hideContent"
  >
    <div
      v-show="shownContent"
      class="popup-content"
    >
      <div class="popup-content-border-wrapper">
        <div class="popup-content-border" />
      </div>
      <button
        class="close-button"
        @click="closePopup"
      />
      <h2 class="title">
        {{ $t('components.popupAboutPromoCode.title') }}
      </h2>
      <p
        class="text"
        v-html="$t('components.popupAboutPromoCode.text')"
      />
      <img
        src="../../assets/media/pages/main/promo.svg"
        class="promo-image"
        alt="pack image"
      >
    </div>
    <v-preload-hidden-images
      :images="[
        require('../../assets/media/common/close-button-hover.svg'),
        require('../../assets/media/common/close-button-active.svg'),
      ]"
    />
  </div>
</template>

<script>
export default {
  name: 'PopupAboutPromoCode',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:opened'],
  data() {
    return {
      shownContent: false,
    };
  },
  watch: {
    opened: {
      immediate: true,
      handler(value) {
        if (value) this.showContent();
      },
    },
  },
  methods: {
    closePopup() {
      this.$emit('update:opened', false);
    },
    showContent() {
      this.shownContent = true;
    },
    hideContent() {
      if (!this.opened) this.shownContent = false;
    },
  },
};
</script>

<style scoped>
.popup-content{
  padding-bottom: 70px;
}
.text{
  font-size: 24px;
  margin: 20px 0 0 20px;
}
.text ::v-deep(a){
  text-decoration: underline;
  color: var(--secondary-color-3);
  font: 400 24px/36px "Montserrat", Arial, sans-serif;
}
.title{
  margin: 45px 0 0 20px;
}
.promo-image{
  display: flex;
  width: 740px;
  height: 380px;
  margin: 40px auto 0;
}
</style>
