export function numberEnd(n, textsOptions) {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return textsOptions[2];
  }
  if (n1 > 1 && n1 < 5) {
    return textsOptions[1];
  }
  if (n1 === 1) {
    return textsOptions[0];
  }
  return textsOptions[2];
}

export function getRandomItems(array, count) {
  const shuffled = array.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
}

export function isTouchDevice() {
  return (('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0));
}
