<template>
  <div
    class="popup popup-level-losing"
    :class="{opened}"
    @transitionend="hideContent"
  >
    <div
      v-show="shownContent"
      class="popup-content"
    >
      <div class="popup-content-border-wrapper">
        <div class="popup-content-border" />
      </div>
      <h2 class="title">
        {{ $t('components.popupLevelLosing.title') }}
      </h2>
      <p class="text1">
        {{ $t('components.popupLevelLosing.text1') }}
      </p>
      <p class="score-value-wrapper">
        <span class="score-value"> {{ internalScoreValue }}  </span>
        <span class="score-value-description"> {{ scoreValueText }} </span>
      </p>
      <p class="text2">
        {{ $t('components.popupLevelLosing.text2') }}
      </p>
      <p class="score-value-wrapper">
        <span class="score-value"> {{ scoreGameValue }}  </span>
        <span class="score-value-description"> {{ scoreGameValueText }} </span>
      </p>
      <p
        class="text3"
        v-html="$t('components.popupLevelLosing.text3', { text: scoreGuruPharmaValueText }) "
      />
      <div class="buttons-wrapper">
        <button
          class="main-button next-button"
          @click="goToNextLevel"
        >
          {{ $t('components.popupLevelLosing.nextButtonTitle') }}
        </button>
      </div>
    </div>
    <v-preload-hidden-images
      :images="[
        require('../../assets/media/components/popup-level-losing/next-button-hover.svg'),
        require('../../assets/media/components/popup-level-losing/next-button-active.svg'),
      ]"
    />
  </div>
</template>

<script>
import { numberEnd } from '@/utils';

export default {
  name: 'PopupLevelLosing',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    scoreValue: {
      type: Number,
      default: 5,
    },
    scoreGameValue: {
      type: Number,
      default: 5,
    },
    scoreGuruPharmaValue: {
      type: Number,
      default: 5,
    },
  },
  emits: ['update:opened', 'goto-next-level'],
  data() {
    return {
      shownContent: false,
      internalScoreValue: 0,
    };
  },
  computed: {
    scoreValueText() {
      return numberEnd(this.internalScoreValue, [
        this.$t('components.popupLevelLosing.scoreTextType1'),
        this.$t('components.popupLevelLosing.scoreTextType2'),
        this.$t('components.popupLevelLosing.scoreTextType3'),
      ]);
    },
    scoreGameValueText() {
      return numberEnd(this.scoreGameValue, [
        this.$t('components.popupLevelLosing.scoreTextType1'),
        this.$t('components.popupLevelLosing.scoreTextType2'),
        this.$t('components.popupLevelLosing.scoreTextType3'),
      ]);
    },
    scoreGuruPharmaValueText() {
      const text = numberEnd(this.scoreGuruPharmaValue, [
        this.$t('components.popupLevelLosing.scoreTextType1'),
        this.$t('components.popupLevelLosing.scoreTextType2'),
        this.$t('components.popupLevelLosing.scoreTextType3'),
      ]);
      return `${this.scoreGuruPharmaValue} ${text}`;
    },
  },
  watch: {
    opened: {
      immediate: true,
      handler(value) {
        if (value) {
          this.internalScoreValue = this.scoreValue;
          this.showContent();
        }
      },
    },
  },
  methods: {
    closePopup() {
      this.$emit('update:opened', false);
    },
    showContent() {
      this.shownContent = true;
    },
    hideContent() {
      if (!this.opened) this.shownContent = false;
    },
    goToNextLevel() {
      this.closePopup();
      this.$emit('goto-next-level');
    },
  },
};
</script>

<style scoped>
.popup-content{
  padding-bottom: 60px;
}
.title{
  margin-top: 50px;
  text-align: center;
}
.text1{
  margin-top: 26px;
  font-size: 20px;
  text-align: center;
}
.score-value-wrapper{
  text-align: center;
  margin-top: 16px;
}
.score-value{
  font-size: 40px;
  font-weight: 700;
}
.score-value-description{
  margin-left: 10px;
}
.text2,
.text3{
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 19px;
  text-align: center;
  line-height: 24px;
}
.buttons-wrapper{
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.next-button{
  width: 334px;
  height: 100px;
  margin: 0 auto;
  background: url("../../assets/media/components/popup-level-losing/next-button-defalut.svg") no-repeat center/contain;
}
.next-button:hover{
  background: url("../../assets/media/components/popup-level-losing/next-button-hover.svg") no-repeat center/contain;
}
.next-button:active{
  background: url("../../assets/media/components/popup-level-losing/next-button-active.svg") no-repeat center/contain;
}
</style>
