<template>
  <div class="characters">
    <v-old-man1-type1 :show="isShowCharacter('OldMan1Type1')" />
    <v-old-man1-type2 :show="isShowCharacter('OldMan1Type2')" />
    <v-old-man1-type3 :show="isShowCharacter('OldMan1Type3')" />
    <v-old-man1-type4 :show="isShowCharacter('OldMan1Type4')" />
    <v-old-man2-type1 :show="isShowCharacter('OldMan2Type1')" />
    <v-old-man2-type2 :show="isShowCharacter('OldMan2Type2')" />
    <v-old-man2-type3 :show="isShowCharacter('OldMan2Type3')" />
    <v-old-man2-type4 :show="isShowCharacter('OldMan2Type4')" />
    <v-old-man2-type5 :show="isShowCharacter('OldMan2Type5')" />
    <v-woman1-type1 :show="isShowCharacter('Woman1Type1')" />
    <v-woman1-type2 :show="isShowCharacter('Woman1Type2')" />
    <v-woman1-type3 :show="isShowCharacter('Woman1Type3')" />
    <v-woman1-type4 :show="isShowCharacter('Woman1Type4')" />
    <v-woman2-type1 :show="isShowCharacter('Woman2Type1')" />
    <v-woman2-type2 :show="isShowCharacter('Woman2Type2')" />
    <v-woman2-type3 :show="isShowCharacter('Woman2Type3')" />
    <v-woman2-type4 :show="isShowCharacter('Woman2Type4')" />
    <v-woman2-type5 :show="isShowCharacter('Woman2Type5')" />
    <v-man1-type1 :show="isShowCharacter('Man1Type1')" />
    <v-man1-type2 :show="isShowCharacter('Man1Type2')" />
    <v-man1-type3 :show="isShowCharacter('Man1Type3')" />
    <v-man1-type4 :show="isShowCharacter('Man1Type4')" />
    <v-man1-type5 :show="isShowCharacter('Man1Type5')" />
    <v-man2-type1 :show="isShowCharacter('Man2Type1')" />
    <v-man2-type2 :show="isShowCharacter('Man2Type2')" />
    <v-man2-type3 :show="isShowCharacter('Man2Type3')" />
    <v-man2-type4 :show="isShowCharacter('Man2Type4')" />
    <v-man2-type5 :show="isShowCharacter('Man2Type5')" />
    <v-old-woman1-type1 :show="isShowCharacter('OldWoman1Type1')" />
    <v-old-woman1-type2 :show="isShowCharacter('OldWoman1Type2')" />
    <v-old-woman1-type3 :show="isShowCharacter('OldWoman1Type3')" />
    <v-old-woman1-type4 :show="isShowCharacter('OldWoman1Type4')" />
    <v-old-woman2-type1 :show="isShowCharacter('OldWoman2Type1')" />
    <v-old-woman2-type2 :show="isShowCharacter('OldWoman2Type2')" />
    <v-old-woman2-type3 :show="isShowCharacter('OldWoman2Type3')" />
    <v-old-woman2-type4 :show="isShowCharacter('OldWoman2Type4')" />
    <v-girl-type1 :show="isShowCharacter('GirlType1')" />
    <v-girl-type2 :show="isShowCharacter('GirlType2')" />
    <v-girl-type3 :show="isShowCharacter('GirlType3')" />
    <v-girl-type4 :show="isShowCharacter('GirlType4')" />
    <v-girl-type5 :show="isShowCharacter('GirlType5')" />
    <v-girl-type6 :show="isShowCharacter('GirlType6')" />
    <v-girl-type7 :show="isShowCharacter('GirlType7')" />
    <v-girl-type8 :show="isShowCharacter('GirlType8')" />
    <v-girl-type9 :show="isShowCharacter('GirlType9')" />
    <v-hipster-hysterical-type1 :show="isShowCharacter('HipsterHystericalType1')" />
    <v-hipster-hysterical-type2 :show="isShowCharacter('HipsterHystericalType2')" />
    <v-hipster-hysterical-type3 :show="isShowCharacter('HipsterHystericalType3')" />
    <v-hipster-hysterical-type4 :show="isShowCharacter('HipsterHystericalType4')" />
    <v-hipster-hysterical-type5 :show="isShowCharacter('HipsterHystericalType5')" />
    <v-hipster-hysterical-type6 :show="isShowCharacter('HipsterHystericalType6')" />
    <v-hipster-hysterical-type7 :show="isShowCharacter('HipsterHystericalType7')" />
  </div>
</template>

<script>
import vOldMan1Type1 from '@/components/characters/OldMan1Type1.vue';
import vOldMan1Type2 from '@/components/characters/OldMan1Type2.vue';
import vOldMan1Type3 from '@/components/characters/OldMan1Type3.vue';
import vOldMan1Type4 from '@/components/characters/OldMan1Type4.vue';
import vOldMan2Type1 from '@/components/characters/OldMan2Type1.vue';
import vOldMan2Type2 from '@/components/characters/OldMan2Type2.vue';
import vOldMan2Type3 from '@/components/characters/OldMan2Type3.vue';
import vOldMan2Type4 from '@/components/characters/OldMan2Type4.vue';
import vOldMan2Type5 from '@/components/characters/OldMan2Type5.vue';
import vOldWoman1Type1 from '@/components/characters/OldWoman1Type1.vue';
import vOldWoman1Type2 from '@/components/characters/OldWoman1Type2.vue';
import vOldWoman1Type3 from '@/components/characters/OldWoman1Type3.vue';
import vOldWoman1Type4 from '@/components/characters/OldWoman1Type4.vue';
import vOldWoman2Type1 from '@/components/characters/OldWoman2Type1.vue';
import vOldWoman2Type2 from '@/components/characters/OldWoman2Type2.vue';
import vOldWoman2Type3 from '@/components/characters/OldWoman2Type3.vue';
import vOldWoman2Type4 from '@/components/characters/OldWoman2Type4.vue';
import vMan1Type1 from '@/components/characters/Man1Type1.vue';
import vMan1Type2 from '@/components/characters/Man1Type2.vue';
import vMan1Type3 from '@/components/characters/Man1Type3.vue';
import vMan1Type4 from '@/components/characters/Man1Type4.vue';
import vMan1Type5 from '@/components/characters/Man1Type5.vue';
import vMan2Type1 from '@/components/characters/Man2Type1.vue';
import vMan2Type2 from '@/components/characters/Man2Type2.vue';
import vMan2Type3 from '@/components/characters/Man2Type3.vue';
import vMan2Type4 from '@/components/characters/Man2Type4.vue';
import vMan2Type5 from '@/components/characters/Man2Type5.vue';
import vWoman1Type1 from '@/components/characters/Woman1Type1.vue';
import vWoman1Type2 from '@/components/characters/Woman1Type2.vue';
import vWoman1Type3 from '@/components/characters/Woman1Type3.vue';
import vWoman1Type4 from '@/components/characters/Woman1Type4.vue';
import vWoman2Type1 from '@/components/characters/Woman2Type1.vue';
import vWoman2Type2 from '@/components/characters/Woman2Type2.vue';
import vWoman2Type3 from '@/components/characters/Woman2Type3.vue';
import vWoman2Type4 from '@/components/characters/Woman2Type4.vue';
import vWoman2Type5 from '@/components/characters/Woman2Type5.vue';
import vGirlType1 from '@/components/characters/GirlType1.vue';
import vGirlType2 from '@/components/characters/GirlType2.vue';
import vGirlType3 from '@/components/characters/GirlType3.vue';
import vGirlType4 from '@/components/characters/GirlType4.vue';
import vGirlType5 from '@/components/characters/GirlType5.vue';
import vGirlType6 from '@/components/characters/GirlType6.vue';
import vGirlType7 from '@/components/characters/GirlType7.vue';
import vGirlType8 from '@/components/characters/GirlType8.vue';
import vGirlType9 from '@/components/characters/GirlType9.vue';
import vHipsterHystericalType1 from '@/components/characters/HipsterHystericalType1.vue';
import vHipsterHystericalType2 from '@/components/characters/HipsterHystericalType2.vue';
import vHipsterHystericalType3 from '@/components/characters/HipsterHystericalType3.vue';
import vHipsterHystericalType4 from '@/components/characters/HipsterHystericalType4.vue';
import vHipsterHystericalType5 from '@/components/characters/HipsterHystericalType5.vue';
import vHipsterHystericalType6 from '@/components/characters/HipsterHystericalType6.vue';
import vHipsterHystericalType7 from '@/components/characters/HipsterHystericalType7.vue';

export default {
  name: 'Characters',
  components: {
    vOldMan1Type1,
    vOldMan1Type2,
    vOldMan1Type3,
    vOldMan1Type4,
    vOldMan2Type1,
    vOldMan2Type2,
    vOldMan2Type3,
    vOldMan2Type4,
    vOldMan2Type5,
    vOldWoman1Type1,
    vOldWoman1Type2,
    vOldWoman1Type3,
    vOldWoman1Type4,
    vOldWoman2Type1,
    vOldWoman2Type2,
    vOldWoman2Type3,
    vOldWoman2Type4,
    vMan1Type1,
    vMan1Type2,
    vMan1Type3,
    vMan1Type4,
    vMan1Type5,
    vMan2Type1,
    vMan2Type2,
    vMan2Type3,
    vMan2Type4,
    vMan2Type5,
    vWoman1Type1,
    vWoman1Type2,
    vWoman1Type3,
    vWoman1Type4,
    vWoman2Type1,
    vWoman2Type2,
    vWoman2Type3,
    vWoman2Type4,
    vWoman2Type5,
    vGirlType1,
    vGirlType2,
    vGirlType3,
    vGirlType4,
    vGirlType5,
    vGirlType6,
    vGirlType7,
    vGirlType8,
    vGirlType9,
    vHipsterHystericalType1,
    vHipsterHystericalType2,
    vHipsterHystericalType3,
    vHipsterHystericalType4,
    vHipsterHystericalType5,
    vHipsterHystericalType6,
    vHipsterHystericalType7,
  },
  props: {
    character: {
      type: String,
      default: '',
    },
  },
  methods: {
    isShowCharacter(value) {
      return this.character === value;
    },
  },
};
</script>

<style scoped>
.characters{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  will-change: transform;
}
</style>
