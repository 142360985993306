<template>
  <header :style="{transform: `scale(${scaleValue})`}">
    <img
      src="../assets/media/pages/main/main-logo.svg"
      alt="Logo"
      class="header-logo"
    >
    <div
      class="menu-burger"
      :class="{opened: menuToggled}"
      @click="toggleMenu"
    >
      <div class="line" />
      <div class="line" />
      <div class="line" />
    </div>
    <div
      class="header-navigation-wrapper"
      :class="{show: menuToggled}"
    >
      <div
        class="menu-item"
        @click="scrollToAboutProduct"
      >
        <img
          src="../assets/media/pages/main/product-info-button.svg"
          alt="Product Info"
        >
        <span>{{ $t('pages.main.about') }}</span>
      </div>
      <div
        class="menu-item"
        @click="openInstruction"
      >
        <img
          src="../assets/media/pages/main/instruction-button.svg"
          alt="Instruction"
        >
        <span>{{ $t('pages.main.instruction') }}</span>
      </div>
      <div
        class="menu-item"
        @click="scrollToHowToPlay"
      >
        <img
          src="../assets/media/pages/main/how-to-play-button.svg"
          alt="Instruction"
        >
        <span>{{ $t('pages.main.howToPlay') }}</span>
      </div>
      <button
        class="main-button play-button"
        @click="goToPageGame"
      >
        {{ $t('pages.main.playButton') }}
      </button>
    </div>
  </header>
</template>

<script>
import pdfInstruction from '@/assets/pdfs/Vertex_Duocold_Game_instruction.pdf';
import mainScale from '@/mixins/main-scale';

export default {
  name: 'MainHeader',
  mixins: [mainScale],
  data() {
    return {
      menuToggled: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuToggled = !this.menuToggled;
    },
    openInstruction() {
      window.open(pdfInstruction, '_blank');
    },
    scrollToAboutProduct() {
      document.querySelector('.section-4 .section-text2').scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    scrollToHowToPlay() {
      document.querySelector('.section-2 .steps-wrapper').scrollIntoView({ block: 'center', behavior: 'smooth' });
      document.querySelector('.section-2 .steps-wrapper-type-2').scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    },
    goToPageGame() {
      this.$router.push('game').catch(() => {
      });
    },
  },
};
</script>

<style scoped>
header{
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 1920px;
  padding: 30px 80px;
  background: #f3fbff;
  transform-origin: center top;
}
header .header-logo{
  width: 170px;
  height: 80px;
  padding-bottom: 10px;
}
header .header-navigation-wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
}
header .menu-item{
  display: flex;
  align-items: center;
  margin-left: 90px;
  cursor: pointer;
}
header .menu-item span{
  margin-left: 15px;
  font-weight: 700;
  color: var(--primary-color);
}
header .menu-item:hover span{
  text-decoration: underline;
}
header .play-button{
  width: 268px;
  height: 78px;
  margin-left: auto;
  margin-top: 2px;
  background: url("../assets/media/pages/main/play-button-default.svg") no-repeat center/contain;
}
header .play-button:hover{
  background: url("../assets/media/pages/main/play-button-hover.svg") no-repeat center/contain;
}
header .play-button:active{
  background: url("../assets/media/pages/main/play-button-active.svg") no-repeat center/contain;
}
.menu-burger{
  display: none;
}
@media (max-width: 1024px){
  header{
    transform: unset !important;
    width: 100%;
    padding: 20px 30px;
  }
  header .header-logo{
    width: 90px;
    height: 48px;
  }
  header .menu-item{
    margin-left: 30px;
  }
  header .menu-item img{
    width: 40px;
    height: 42px;
  }
  header .menu-item span{
    margin-left: 10px;
    font-size: 14px;
  }
  header .play-button{
    width: 142px;
    height: 55px;
    font-size: 14px;
    padding-bottom: 5px;
  }
}
@media (max-width: 900px){
  header{
    padding: 20px 20px;
  }
  header .menu-item span{
    font-size: 12px;
  }
}
@media (max-width: 800px){
  header{
    flex-direction: column;
    justify-content: center;
  }
  header .header-navigation-wrapper{
    margin-top: 20px;
  }
  header .menu-item:first-of-type{
    margin-left: 0;
  }
  header .play-button{
    margin-left: 30px;
  }
}
@media (max-width: 700px){
  header .header-navigation-wrapper{
    flex-wrap: wrap;
    justify-content: center;
  }
  header .play-button{
    flex-basis: 100%;
    height: 46px;
    margin-top: 20px;
    padding-bottom: 7px;
  }
}
@media (max-width: 550px){
  header .header-navigation-wrapper{
    display: none;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  .menu-burger{
    display: block;
    position: absolute;
    top: 30px;
    right: 20px;
    width: 30px;
    height: 30px;
  }
  .menu-burger .line{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: var(--primary-color);
    transition: transform 0.2s;
    transform-origin: right;
  }
  .menu-burger .line:nth-of-type(2){
    top: 50%;
    transform: translateY(-50%);
  }
  .menu-burger .line:last-child{
    top: unset;
    right: 0;
    bottom: 0;
  }
  .menu-burger.opened .line:first-child{
    width: 40px;
    transform: rotate(-45deg);
  }
  .menu-burger.opened .line:last-child{
    width: 40px;
    transform: rotate(45deg);
  }
  .menu-burger.opened .line:nth-of-type(2){
    opacity: 0;
    transition: opacity 0.1s;
  }
  header .header-navigation-wrapper.show{
    display: flex;
    opacity: 1;
  }
  header .header-navigation-wrapper .menu-item{
    width: 140px;
    margin: 10px 0 0 0;
  }
  header .play-button{
    flex-basis: unset;
    width: 180px;
    height: 52px;
    margin: 20px auto 0;
    font-size: 18px;
  }
}
@media (max-width: 450px){
  .menu-burger{
    top: 30px;
    right: 15px;
  }
}
</style>
