<template>
  <div class="flowerpot-animation">
    <div class="leaf1" />
    <div class="leaf2" />
    <div class="leaf3" />
    <div class="leaf4" />
    <div class="pot" />
  </div>
</template>

<script>
export default {
  name: 'FlowerpotAnimation',
};
</script>

<style scoped>
.flowerpot-animation{
  position: absolute;
  width: 240px;
  height: 410px;
}
.pot{
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 204px;
  height: 136px;
  background: url("../assets/media/components/flowerpot-animation/pot.svg") no-repeat center/contain;
}
.leaf1{
  position: absolute;
  top: 152px;
  left: -74px;
  z-index: 1;
  width: 153px;
  height: 125px;
  transform-origin: bottom right;
  background: url("../assets/media/components/flowerpot-animation/leaf1.svg") no-repeat center/contain;
  animation: leafRight 6s infinite;
}
.leaf2{
  position: absolute;
  top: 4px;
  left: 20px;
  z-index: 3;
  width: 119px;
  height: 271px;
  transform-origin: 70px 275px;
  animation: leafLeft 6s infinite;
  background: url("../assets/media/components/flowerpot-animation/leaf2.svg") no-repeat center/contain;
}
.leaf3{
  position: absolute;
  top: 101px;
  left: 53px;
  z-index: 2;
  width: 145px;
  height: 175px;
  transform-origin: 40px 185px;
  background: url("../assets/media/components/flowerpot-animation/leaf3.svg") no-repeat center/contain;
  animation: leafRight 7s 0.5s infinite;
}
.leaf4{
  position: absolute;
  top: 131px;
  left: 116px;
  z-index: 4;
  width: 118px;
  height: 144px;
  transform-origin: 40px 145px;
  background: url("../assets/media/components/flowerpot-animation/leaf4.svg") no-repeat center/contain;
  animation: leafLeft 7s 0.5s infinite;
}
@keyframes leafRight{
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(10deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
@keyframes leafLeft{
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(-10deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
</style>
