export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animate: false,
      hide: false,
    };
  },
  watch: {
    show: {
      handler(value) {
        if (value) this.animate = true;
        else this.hide = true;
      },
    },
  },
  methods: {
    onTransitionEnd() {
      this.animate = false;
      this.hide = false;
    },
  },
};
