import { createApp } from 'vue';
import { createStore } from 'vuex';
import * as VueRouter from 'vue-router';
import loader from 'vue-ui-preloader';
import i18n from './i18n';
import App from './App.vue';
import modules from './store';
import routes from './routes/routes';
import vPreloadHiddenImages from '@/components/PreloadHiddenImages.vue';

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
});

const store = createStore(modules);

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(loader)
  .component('vPreloadHiddenImages', vPreloadHiddenImages)
  .mount('#app');
