import axios from 'axios';

export default class User {
  constructor() {
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_SERVER_URL,
      withCredentials: false,
    });
  }
}
