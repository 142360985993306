<template>
  <div class="slider-timer">
    <span class="title">{{ $t('components.sliderTime.title') }}</span>
    <div class="slider-wrapper">
      <div
        class="slider-field"
        :style="sliderFieldStyle"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderTime',
  props: {
    translateX: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    sliderFieldStyle() {
      return {
        transform: `translateX(${this.translateX}%)`,
      };
    },
  },
};
</script>

<style scoped>
.slider-timer{
  width: 560px;
  height: 40px;
}
.title{
  font-size: 14px;
  text-transform: uppercase;
  color: var(--primary-color);
}
.slider-wrapper{
  width: 100%;
  height: 30px;
  margin-top: 3px;
  overflow: hidden;
  border-radius: 15px;
  background: #dee8ff;
  border: 6px solid #ffffff;
}
.slider-field{
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(to top, #ffba00 50%, #ffe959 50%);
  transition: transform 0.3s;
  will-change: transform;
}
</style>
