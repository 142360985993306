<template>
  <div
    class="popup popup-specification"
    :class="{opened}"
    @transitionend="hideContent"
  >
    <div
      v-show="shownContent"
      class="popup-content"
    >
      <div class="popup-content-border-wrapper">
        <div class="popup-content-border" />
      </div>
      <button
        class="close-button"
        @click="closePopup"
      />
      <div
        v-show="enableIntro"
        class="title-wrapper"
      >
        <img
          src="../../assets/media/common/information-icon.svg"
          class="information-icon"
          alt="information-icon"
        >
        <h2 class="title">
          {{ $t('components.popupSpecification.introTitle') }}
        </h2>
      </div>
      <span
        v-show="enableIntro"
        class="specification-text intro-text"
      >
        {{ $t('components.popupSpecification.introText') }}
      </span>
      <div class="title-wrapper">
        <img
          src="../../assets/media/common/information-icon.svg"
          class="information-icon"
          alt="information-icon"
        >
        <h2 class="title">
          {{ $t('components.popupSpecification.title') }}
        </h2>
      </div>
      <ul class="list">
        <li
          v-for="(item, index) in specification"
          :key="`specification-${index}`"
        >
          <img
            src="../../assets/media/common/check-icon.svg"
            class="check-icon"
            alt="check-icon"
          >
          <span class="specification-text">{{ item }}</span>
        </li>
      </ul>
      <button
        class="main-button start-game-button"
        @click="startGame"
      >
        {{ startGameButtonText }}
      </button>
    </div>
    <v-preload-hidden-images
      :images="[
        require('../../assets/media/common/close-button-hover.svg'),
        require('../../assets/media/common/close-button-active.svg'),
        require('../../assets/media/components/popup-specification/start-game-button-hover.svg'),
        require('../../assets/media/components/popup-specification/start-game-button-active.svg')
      ]"
    />
  </div>
</template>

<script>
export default {
  name: 'PopupSpecification',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    disableGame: {
      type: Boolean,
      default: false,
    },
    enableIntro: {
      type: Boolean,
      default: false,
    },
    specification: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:opened', 'start-game'],
  data() {
    return {
      shownContent: false,
    };
  },
  computed: {
    startGameButtonText() {
      if (this.disableGame) return this.$t('components.popupSpecification.startGameTitle');
      return this.$t('components.popupSpecification.proceedGameTitle');
    },
  },
  watch: {
    opened: {
      immediate: true,
      handler(value) {
        if (value) this.showContent();
      },
    },
  },
  methods: {
    closePopup() {
      this.$emit('update:opened', false);
    },
    showContent() {
      this.shownContent = true;
    },
    hideContent() {
      if (!this.opened) this.shownContent = false;
    },
    startGame() {
      this.closePopup();
      this.$emit('start-game');
    },
  },
};
</script>

<style scoped>
.title-wrapper{
  display: flex;
  margin-top: 70px;
}
.title{
  margin-left: 20px;
}
.specification-text.intro-text{
  display: block;
  margin: 30px 0 0 35px;
}
.check-icon{
  width: 20px;
  height: 19px;
  margin-bottom: 2px;
}
.list{
  margin: 50px 0;
  padding: 0;
  list-style: none;
}
.list li{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.specification-text{
  margin-left: 15px;
  font-size: 18px;
  line-height: 25px;
}
.start-game-button{
  position: absolute;
  left: 50%;
  bottom: 50px;
  width: 320px;
  height: 100px;
  background: url("../../assets/media/components/popup-specification/start-game-button-default.svg") no-repeat center/contain;
  transform: translateX(-50%);
}
.start-game-button:hover{
  background: url("../../assets/media/components/popup-specification/start-game-button-hover.svg") no-repeat center/contain;
}
.start-game-button:active{
  background: url("../../assets/media/components/popup-specification/start-game-button-active.svg") no-repeat center/contain;
}
</style>
