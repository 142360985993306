import mainPage from '../pages/Main.vue';
import gamePage from '../pages/Game.vue';
import introPage from '../pages/Intro.vue';
import promoCodePage from '../pages/PromoCode.vue';
import conclusionPage from '../pages/Conclusion.vue';

export default [
  {
    path: '/',
    component: introPage,
  },
  {
    path: '/main',
    component: mainPage,
  },
  {
    path: '/game',
    component: gamePage,
  },
  {
    path: '/conclusion',
    component: conclusionPage,
  },
  {
    path: '/promo-code',
    component: promoCodePage,
  },
];
