<template>
  <div
    class="page"
    :style="{transform: `scale(${scaleValue})`}"
  >
    <section class="section-1">
      <div class="left-column">
        <h2
          class="section-title"
          v-html="$t('pages.main.section1.title')"
        />
        <p class="section-text">
          {{ $t('pages.main.section1.text1') }}
        </p>
        <p
          class="section-text"
          v-html="$t('pages.main.section1.text2')"
        />
        <button
          class="main-button start-game-button"
          @click="goToPageGame"
        >
          {{ $t('pages.main.section1.startGame') }}
        </button>
      </div>
      <div class="right-column">
        <img
          src="../assets/media/pages/main/pack1.png"
          class="pack1-image"
          alt="pack image"
        >
        <div class="pack2-wrapper">
          <img
            src="../assets/media/pages/main/pack2.svg"
            class="pack2-image"
            alt="pack image"
          >
          <span class="pack2-text">{{ $t('pages.main.section1.text3') }}</span>
        </div>
        <div class="pack3-wrapper">
          <img
            src="../assets/media/pages/main/pack3.svg"
            class="pack3-image"
            alt="pack image"
          >
          <span class="pack3-text">{{ $t('pages.main.section1.text4') }}</span>
        </div>
      </div>
    </section>
    <section class="section-2">
      <div class="left-column">
        <h2
          class="section-title"
          v-html="$t('pages.main.section2.title1')"
        />
        <p
          class="section-text section-text1"
          v-html="$t('pages.main.section2.text1')"
        />
      </div>
      <div class="right-column">
        <img
          src="../assets/media/pages/main/promo.png"
          class="promo-image"
          alt="pack image"
        >
      </div>
      <h2
        class="section-title section-title2"
        v-html="$t('pages.main.section2.title2')"
      />
      <div class="steps-wrapper">
        <img
          src="../assets/media/pages/main/step1.svg"
          class="step1-image"
          alt="step1"
        >
        <img
          src="../assets/media/pages/main/step2.svg"
          class="step2-image"
          alt="step2"
        >
        <img
          src="../assets/media/pages/main/step3.svg"
          class="step3-image"
          alt="step3"
        >
        <img
          src="../assets/media/pages/main/step4.svg"
          class="step4-image"
          alt="step4"
        >
        <img
          src="../assets/media/pages/main/step5.svg"
          class="step5-image"
          alt="step5"
        >
        <img
          src="../assets/media/pages/main/arrow1.svg"
          class="arrow1-image"
          alt="arrow1"
        >
        <img
          src="../assets/media/pages/main/arrow2.svg"
          class="arrow2-image"
          alt="arrow2"
        >
        <img
          src="../assets/media/pages/main/arrow1.svg"
          class="arrow3-image"
          alt="arrow3"
        >
        <img
          src="../assets/media/pages/main/arrow2.svg"
          class="arrow4-image"
          alt="arrow4"
        >
        <span
          class="step-text step1-text"
          v-html="$t('pages.main.section2.step1')"
        />
        <span
          class="step-text step2-text"
          v-html="$t('pages.main.section2.step2')"
        />
        <span
          class="step-text step3-text"
          v-html="$t('pages.main.section2.step3')"
        />
        <span
          class="step-text step4-text"
          v-html="$t('pages.main.section2.step4')"
        />
        <span
          class="step-text step5-text"
          v-html="$t('pages.main.section2.step5')"
        />
      </div>
      <div class="steps-wrapper-type-2">
        <div class="step-wrapper">
          <img
            src="../assets/media/pages/main/step1.svg"
            class="step1-image"
            alt="step1"
          >
          <span
            class="step-text step1-text"
            v-html="$t('pages.main.section2.step1')"
          />
        </div>
        <div class="step-wrapper">
          <img
            src="../assets/media/pages/main/step2.svg"
            class="step2-image"
            alt="step2"
          >
          <span
            class="step-text step2-text"
            v-html="$t('pages.main.section2.step2')"
          />
        </div>
        <div class="step-wrapper">
          <img
            src="../assets/media/pages/main/step3.svg"
            class="step3-image"
            alt="step3"
          >
          <span
            class="step-text step3-text"
            v-html="$t('pages.main.section2.step3')"
          />
        </div>
        <div class="step-wrapper">
          <img
            src="../assets/media/pages/main/step4.svg"
            class="step4-image"
            alt="step4"
          >
          <span
            class="step-text step4-text"
            v-html="$t('pages.main.section2.step4')"
          />
        </div>
        <div class="step-wrapper">
          <img
            src="../assets/media/pages/main/step5.svg"
            class="step5-image"
            alt="step5"
          >
          <span
            class="step-text step5-text"
            v-html="$t('pages.main.section2.step5')"
          />
        </div>
      </div>
    </section>
    <section class="section-3">
      <div class="left-column">
        <h2
          class="section-title"
          v-html="$t('pages.main.section3.title')"
        />
        <button
          class="main-button start-game-button"
          @click="goToPageGame"
        >
          {{ $t('pages.main.section1.startGame') }}
        </button>
      </div>
      <div class="right-column">
        <div
          class="old-woman-wrapper"
          :class="{animate: oldWomanAnimate}"
          @mouseenter="oldWomanClickHandler"
          @click="oldWomanClickHandler"
        >
          <img
            src="../assets/media/pages/main/old-woman-box.svg"
            class="old-woman-box"
            alt="old woman box"
          >
          <img
            src="../assets/media/pages/main/old-woman-hand.svg"
            class="old-woman-hand"
            alt="old woman hand"
          >
          <img
            src="../assets/media/pages/main/old-woman-body.svg"
            class="old-woman-body"
            alt="old woman body"
          >
        </div>
      </div>
    </section>
    <section class="section-4">
      <h3
        class="section-text section-text1"
        v-html="$t('pages.main.section4.text1')"
      />
      <p
        class="section-text section-text2"
        v-html="$t('pages.main.section4.text2')"
      />
      <div class="features-wrapper">
        <div class="feature-wrapper">
          <img
            src="../assets/media/pages/main/feature1.svg"
            class="feature-image feature1-image"
            alt="feature 1 image"
          >
          <span
            class="feature-text feature1-text"
            v-html="$t('pages.main.section4.feature1')"
          />
        </div>
        <div class="feature-wrapper">
          <img
            src="../assets/media/pages/main/feature2.svg"
            class="feature-image feature2-image"
            alt="feature 2 image"
          >
          <span
            class="feature-text feature2-text"
            v-html="$t('pages.main.section4.feature2')"
          />
        </div>
        <div class="feature-wrapper">
          <img
            src="../assets/media/pages/main/feature3.svg"
            class="feature-image feature3-image"
            alt="feature 3 image"
          >
          <span
            class="feature-text feature3-text"
            v-html="$t('pages.main.section4.feature3')"
          />
        </div>
        <div class="feature-wrapper">
          <img
            src="../assets/media/pages/main/feature4.svg"
            class="feature-image feature4-image"
            alt="feature 4 image"
          >
          <span
            class="feature-text feature4-text"
            v-html="$t('pages.main.section4.feature4')"
          />
        </div>
        <div class="feature-wrapper">
          <img
            src="../assets/media/pages/main/feature5.svg"
            class="feature-image feature5-image"
            alt="feature 5 image"
          >
          <span
            class="feature-text feature5-text"
            v-html="$t('pages.main.section4.feature5')"
          />
        </div>
        <div class="feature-wrapper">
          <img
            src="../assets/media/pages/main/feature6.svg"
            class="feature-image feature6-image"
            alt="feature 6 image"
          >
          <span
            class="feature-text feature6-text"
            v-html="$t('pages.main.section4.feature6')"
          />
        </div>
      </div>
    </section>
    <section class="section-5">
      <div class="line-wrapper">
        <div class="point-wrapper">
          <span
            class="point-text"
            v-html="$t('pages.main.section5.point1')"
          />
          <div class="point" />
        </div>
        <div class="point-wrapper">
          <span
            class="point-text"
            v-html="$t('pages.main.section5.point2')"
          />
          <div class="point" />
        </div>
        <div class="point-wrapper">
          <span
            class="point-text"
            v-html="$t('pages.main.section5.point3')"
          />
          <div class="point" />
        </div>
      </div>
      <div class="packs-dosage-wrapper">
        <div class="pack-dosage-wrapper">
          <img
            src="../assets/media/pages/main/pack-dosage.png"
            class="pack-dosage"
            alt="pack dosage"
          >
          <span
            class="pack-dosage-text"
            v-html="$t('pages.main.section5.dosage1')"
          />
        </div>
        <div class="pack-dosage-wrapper">
          <img
            src="../assets/media/pages/main/pack-dosage.png"
            class="pack-dosage"
            alt="pack dosage"
          >
          <span
            class="pack-dosage-text"
            v-html="$t('pages.main.section5.dosage2')"
          />
        </div>
        <div class="pack-dosage-wrapper">
          <img
            src="../assets/media/pages/main/pack-dosage.png"
            class="pack-dosage"
            alt="pack dosage"
          >
          <span
            class="pack-dosage-text"
            v-html="$t('pages.main.section5.dosage3')"
          />
        </div>
      </div>
    </section>
    <section class="section-6">
      <div class="left-column">
        <img
          src="../assets/media/pages/main/exclamation-mark-icon.svg"
          class="exclamation-mark-icon"
          alt="exclamation mark"
        >
      </div>
      <div class="right-column">
        <h3
          class="section-caption"
          v-html="$t('pages.main.section6.caption')"
        />
        <p
          class="section-text"
          v-html="$t('pages.main.section6.text')"
        />
      </div>
    </section>
    <section class="section-7">
      <p
        class="footnote-text"
        v-html="$t('pages.main.section7.footnote1')"
      />
      <p
        class="footnote-text"
        v-html="$t('pages.main.section7.footnote2')"
      />
      <p
        class="footnote-text"
        v-html="$t('pages.main.section7.footnote3')"
      />
    </section>
    <footer>
      <a
        href="https://vertex.spb.ru/?utm_source=pharma-guru"
        target="_blank"
      >
        <img
          src="../assets/media/pages/main/logo-icon.svg"
          alt="Logo"
          class="footer-logo"
        >
      </a>
      <div class="footer-navigation-wrapper">
        <span
          class="footer-text about"
          @click="scrollToAboutProduct"
        >
          {{ $t('pages.main.footer.about') }}
        </span>
        <span
          class="footer-text instruction"
          @click="openInstruction"
        >{{ $t('pages.main.footer.instruction') }}</span>
        <span
          class="footer-text download"
          @click="openSpecification"
        >
          {{ $t('pages.main.footer.download') }}
        </span>
        <span
          class="footer-text privacy"
          @click="openPolicy"
        >
          {{ $t('pages.main.footer.privacy') }}
        </span>
      </div>
      <div class="approve-wrapper">
        <span
          class="footer-approve"
          v-html="$t('pages.main.footer.approve')"
        />
      </div>
    </footer>
    <v-preload-hidden-images
      :images="[
        require('../assets/media/pages/main/play-button-default.svg'),
        require('../assets/media/pages/main/play-button-hover.svg'),
        require('../assets/media/pages/main/play-button-active.svg'),
        require('../assets/media/pages/main/start-game-button-hover.svg'),
        require('../assets/media/pages/main/start-game-button-active.svg')
      ]"
    />
  </div>
  <v-scroll-to-top />
</template>

<script>
import vScrollToTop from '@/components/ScrollToTop.vue';
import mainScale from '@/mixins/main-scale';
import pdfPolicy from '@/assets/pdfs/Vertex_Duocold_Game_2021_policy.pdf';
import pdfInstruction from '@/assets/pdfs/Vertex_Duocold_Game_instruction.pdf';
import pdfSpecification from '@/assets/pdfs/Vertex_Duocold_Game_2021_specification.pdf';

export default {
  name: 'Main',
  components: {
    vScrollToTop,
  },
  mixins: [mainScale],
  data() {
    return {
      oldWomanAnimate: false,
      oldWomanAnimateTimeID: null,
    };
  },
  created() {
    this.setupOldWomanAnimation();
  },
  methods: {
    openInstruction() {
      window.open(pdfInstruction, '_blank');
    },
    openSpecification() {
      window.open(pdfSpecification, '_blank');
    },
    openPolicy() {
      window.open(pdfPolicy, '_blank');
    },
    oldWomanClickHandler() {
      if (this.oldWomanAnimate) return;

      this.oldWomanAnimate = true;

      clearInterval(this.oldWomanAnimateTimeID);

      setTimeout(() => {
        this.oldWomanAnimate = false;
        this.setupOldWomanAnimation();
      }, 3500);
    },
    setupOldWomanAnimation() {
      this.oldWomanAnimateTimeID = setInterval(() => {
        this.oldWomanAnimate = !this.oldWomanAnimate;
      }, 7000);
    },
    scrollToAboutProduct() {
      document.querySelector('.section-4 .section-text2').scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    goToPageGame() {
      this.$router.push('game').catch(() => {
      });
    },
  },
};
</script>

<style scoped>
.page{
  padding: 30px 80px 60px;
  width: 1920px;
  height: 5175px;
  transform-origin: center top;
  background: url("../assets/media/pages/main/bg.jpg") no-repeat center/cover;
}
/* Common page start */
.start-game-button{
  width: 490px;
  height: 98px;
  margin-top: 50px;
  margin-bottom: 12px;
  background: url("../assets/media/pages/main/start-game-button-default.svg") no-repeat center/contain;
}
.start-game-button:hover{
  background: url("../assets/media/pages/main/start-game-button-hover.svg") no-repeat center/contain;
}
.start-game-button:active{
  background: url("../assets/media/pages/main/start-game-button-active.svg") no-repeat center/contain;
}
.section-title{
  font-size: 80px;
  line-height: 86px;
}
.section-text{
  font-size: 24px;
}
.section-text::v-deep(a){
  text-decoration: underline;
  color: var(--secondary-color-3);
  font: 400 24px/36px "Montserrat", Arial, sans-serif;
}
/* Common page end */
.section-1{
  display: flex;
  position: relative;
  height: 916px;
}
.section-1 .left-column{
  width: 41%;
  padding-top: 236px;
}
.section-1 .right-column{
  width: 59%;
}
.section-1 .left-column .section-text{
  margin-top: 50px;
}
.section-1 .left-column .section-text:nth-of-type(2){
  margin-top: 24px;
}
.section-1 .section-text::v-deep(a){
  font: 400 28px/26px "Montserrat", Arial, sans-serif;
}
.section-1 .pack1-image{
  display: flex;
  width: 610px;
  height: 570px;
  margin: 205px 0 0 auto;
}
.section-1 .pack2-wrapper{
  position: absolute;
  bottom: 141px;
  right: 58px;
  display: flex;
  width: 660px;
}
.section-1 .pack2-image{
  width: 198px;
  height: 152px;
}
.section-1 .pack2-text{
  margin-top: 48px;
  margin-left: 25px;
  font-weight: 700;
}
.section-1 .pack3-wrapper{
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  width: 630px;
}
.section-1 .pack3-image{
  width: 198px;
  height: 152px;
}
.section-1 .pack3-text{
  margin-top: 37px;
  margin-left: 25px;
  font-weight: 700;
}
.section-2{
  display: flex;
  flex-wrap: wrap;
  padding-top: 170px;
}
.section-2 .left-column{
  width: 41%;
}
.section-2 .right-column{
  width: 59%;
}
.section-2 .section-text1{
  margin-top: 55px;
  width: 690px;
}
.section-2 .section-title2{
  margin-top: 120px;
}
.section-2 .promo-image{
  display: flex;
  width: 740px;
  height: 380px;
  margin-left: 170px;
}
.steps-wrapper-type-2{
  display: none;
}
.section-2 .steps-wrapper{
  position: relative;
  width: 100%;
  height: 540px;
  margin-top: 120px;
}
.section-2 .step1-image{
  position: absolute;
  top: 14px;
  left: 25px;
  width: 240px;
  height: 216px;
}
.section-2 .step2-image{
  position: absolute;
  top: 85px;
  left: 415px;
  width: 270px;
  height: 270px;
}
.section-2 .step3-image{
  position: absolute;
  top: 0;
  left: 777px;
  width: 240px;
  height: 245px;
}
.section-2 .step4-image{
  position: absolute;
  top: 120px;
  left: 1147px;
  width: 215px;
  height: 234px;
}
.section-2 .step5-image{
  position: absolute;
  top: 20px;
  left: 1507px;
  width: 215px;
  height: 215px;
}
.section-2 .arrow1-image{
  position: absolute;
  top: 106px;
  left: 294px;
  width: 97px;
  height: 92px;
}
.section-2 .arrow2-image{
  position: absolute;
  top: 171px;
  left: 666px;
  width: 97px;
  height: 92px;
}
.section-2 .arrow3-image{
  position: absolute;
  top: 51px;
  left: 1036px;
  width: 97px;
  height: 92px;
}
.section-2 .arrow4-image{
  position: absolute;
  top: 160px;
  left: 1402px;
  width: 97px;
  height: 93px;
}
.section-2 .step-text{
  position: absolute;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
}
.section-2 .step1-text{
  top: 259px;
  left: 18px;
}
.section-2 .step2-text{
  top: 382px;
  left: 432px;
}
.section-2 .step3-text{
  top: 260px;
  left: 758px;
}
.section-2 .step4-text{
  top: 384px;
  left: 1127px;
}
.section-2 .step5-text{
  top: 259px;
  left: 1518px;
}
.section-3{
  display: flex;
}
.section-3 .left-column{
  padding-top: 413px;
  width: 41%;
}
.section-3 .right-column{
  padding: 287px 0 0 170px;
  width: 59%;
}
.section-3 .old-woman-wrapper{
  position: relative;
  width: 575px;
  height: 520px;
  margin-left: 240px;
}
.section-3 .old-woman-box{
  position: absolute;
  top: 130px;
  left: -240px;
  width: 310px;
  height: 97px;
}
.section-3 .old-woman-hand{
  position: absolute;
  top: 168px;
  left: 23px;
  width: 108px;
  height: 343px;
  transform-origin: 50px top;
}
.section-3 .old-woman-body{
  position: absolute;
  top: 0;
  left: 0;
  width: 336px;
  height: 519px;
}
.section-3 .old-woman-wrapper.animate .old-woman-box{
  animation: fadeBox 3s 0.5s backwards;
}
.section-3 .old-woman-wrapper.animate .old-woman-hand{
  animation: rotateInLeft 3s 0.5s backwards;
}
.section-4{
  padding-top: 30px;
}
.section-4 .section-text1{
  margin-bottom: 0;
  font-size: 40px;
  color: #ff0000;
}
.section-4 .section-text2{
  margin-top: 40px;
  width: 800px;
}
.section-4 .features-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1760px;
}
.section-4 .feature-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 293px;
  margin-top: 70px;
}
.section-4 .feature-text{
  margin-top: 30px;
  text-align: center;
}
.section-4 .feature-image{
  width: 215px;
}
.section-4 .feature2-image{
  width: 250px;
}
.section-5{
  margin-top: 96px;
}
.section-5 .line-wrapper{
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
  height: 110px;
}
.section-5 .line-wrapper:before{
  content: "";
  position: absolute;
  top: 68px;
  left: 50%;
  width: 96%;
  height: 4px;
  transform: translateX(-50%);
  background-color: var(--secondary-color-3);
}
.section-5 .point-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}
.section-5 .point-text{
  font-size: 24px;
}
.section-5 .point{
  width: 47px;
  height: 47px;
  margin-top: 21px;
  border-radius: 50%;
  background-color: var(--secondary-color-3);
}
.section-5 .packs-dosage-wrapper{
  display: flex;
  justify-content: space-between;
  margin-top: 58px;
}
.section-5 .pack-dosage-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
}
.section-5 .pack-dosage{
  width: 326px;
  height: 188px;
}
.section-5 .pack-dosage-text{
  margin-top: 65px;
  font-size: 24px;
  line-height: 30px;
}
.section-6{
  display: flex;
  margin-top: 156px;
}
.section-6 .right-column{
  margin-left: 55px;
  padding-top: 10px;
}
.section-6 .exclamation-mark-icon{
  width: 98px;
  height: 98px;
}
.section-6 .section-caption{
  margin: 0;
  font-size: 40px;
}
.section-6 .section-text{
  padding-right: 50px;
  margin-top: 28px;
  font-size: 18px;
  line-height: 24px;
  opacity: 0.5;
}
.section-7{
  margin-top: 127px;
}
.section-7 .footnote-text{
  font-size: 18px;
  line-height: 27px;
  margin-top: 11px;
  opacity: 0.5;
}
footer{
  display: flex;
  align-items: center;
  margin-top: 33px;
}
footer .footer-text{
  font: 16px/20px "Montserrat", Arial, sans-serif;
  text-transform: uppercase;
  margin-left: 82px;
  cursor: pointer;
}
footer .footer-logo{
  margin: 24px 0;
}
footer .about{
  margin-left: 112px;
}
footer .approve-wrapper{
  display: flex;
  margin-left: auto;
  opacity: 0.3;
}
@keyframes rotateInLeft{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(45deg);
  }
  100%{
    transform: rotate(0);
  }
}
@keyframes fadeBox{
  0%{
    opacity: 1;
  }
  20%{
    opacity: 0;
  }
  80%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@media (max-width: 1024px){
  .page{
    padding: 78px 30px 0;
    width: 100%;
    height: unset;
    overflow: unset;
    transform: unset !important;
    background: none;
  }
  /* Common page start */
  .start-game-button{
    width: 262px;
    height: 52px;
    margin-top: 30px;
    font-size: 18px;
  }
  .section-title{
    font-size: 48px;
    line-height: 54px;
  }
  .section-text{
    font-size: 14px;
    line-height: 16px;
  }
  .section-text::v-deep(a){
    font: 400 16px/18px "Montserrat", Arial, sans-serif;
  }
  /* Common page end */
  .section-1{
    height: 530px;
  }
  .section-1 .left-column{
    padding-top: 136px;
  }
  .section-1 .section-text::v-deep(a){
    font: 400 16px/22px "Montserrat", Arial, sans-serif;
  }
  .section-1 .pack1-image{
    width: 325px;
    height: 304px;
    margin: 105px 0 0 auto;
  }
  .section-1 .pack2-wrapper{
    bottom: 90px;
    right: 50px;
    width: 400px;
  }
  .section-1 .pack2-image{
    width: 101px;
    height: 81px;
  }
  .section-1 .pack2-text{
    font-size: 12px;
    line-height: 14px;
    margin-top: 16px;
  }
  .section-1 .pack3-wrapper{
    width: 400px;
  }
  .section-1 .pack3-image{
    width: 101px;
    height: 81px;
  }
  .section-1 .pack3-text{
    font-size: 12px;
    line-height: 14px;
    margin-top: 16px;
  }
  .section-2 .section-text1{
    margin-top: 35px;
    width: unset;
  }
  .section-2 .section-title2{
    white-space: nowrap;
    margin-top: 80px;
  }
  .section-2 .promo-image{
    width: 395px;
    height: 202px;
    margin-left: auto;
  }
  .section-2 .steps-wrapper{
    display: none;
  }
  .section-2 .steps-wrapper-type-2{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }
  .section-2 .step-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 240px;
    height: 320px;
    padding-top: 30px;
  }
  .section-2 .step1-image{
    position: static;
    width: 187px;
    height: 168px;
  }
  .section-2 .step2-image{
    position: static;
    width: 187px;
    height: 191px;
  }
  .section-2 .step3-image{
    position: static;
    width: 187px;
    height: 191px;
  }
  .section-2 .step4-image{
    position: static;
    width: 168px;
    height: 182px;
  }
  .section-2 .step5-image{
    position: static;
    width: 168px;
    height: 167px;
  }
  .section-2 .step-text{
    position: static;
    margin-top: auto;
    height: 70px;
    font-size: 14px;
    line-height: 16px;
  }
  .section-3 .left-column{
    padding-top: 180px;
  }
  .section-3 .right-column{
    padding: 0 0 0 60px;
  }
  .section-3 .old-woman-wrapper{
    width: 375px;
    height: 410px;
    margin-left: auto;
    transform: scale(0.6);
  }
  .section-4 .section-text1{
    font-size: 24px;
    line-height: 24px;
  }
  .section-4 .section-text2{
    margin-top: 20px;
    width: 500px;
  }
  .section-4 .features-wrapper{
    width: 100%;
  }
  .section-4 .feature-wrapper{
    width: 230px;
    margin-top: 40px;
    height: 260px;
  }
  .section-4 .feature-image{
    width: 165px;
    height: 165px;
  }
  .section-4 .feature2-image{
    width: 200px;
    height: 172px;
  }
  .section-4 .feature-text{
    margin-top: auto;
    font-size: 14px;
    line-height: 16px;
    height: 70px;
  }
  .section-5{
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .section-5 .line-wrapper{
    flex-direction: column;
    margin-right: 100px;
    height: unset;
    padding: 0;
  }
  .section-5 .line-wrapper:before{
    top: 50%;
    left: 18px;
    width: 4px;
    height: 90%;
    transform: translate(-50%, -50%);
  }
  .section-5 .point-wrapper{
    flex-direction: row-reverse;
    justify-content: start;
    height: 33%;
  }
  .section-5 .point{
    width: 37px;
    height: 37px;
    margin-top: 0;
    margin-right: 20px;
  }
  .section-5 .point-text{
    font-size: 14px;
  }
  .section-5 .point-wrapper{
    width: 100%;
  }
  .section-5 .packs-dosage-wrapper{
    flex-direction: column;
    margin-top: 0;
  }
  .section-5 .pack-dosage-wrapper{
    width: 100%;
    height: 33%;
    padding-top: 40px;
  }
  .section-5 .pack-dosage{
    width: 250px;
    height: 144px;
  }
  .section-5 .pack-dosage-text{
    margin-top: 25px;
    font-size: 14px;
    line-height: 16px;
  }
  .section-6{
    margin-top: 50px;
  }
  .section-6 .exclamation-mark-icon{
    width: 52px;
    height: 52px;
  }
  .section-6 .section-caption{
    font-size: 24px;
  }
  .section-6 .section-text{
    padding-right: 30px;
    margin-top: 18px;
    font-size: 14px;
    line-height: 16px;
  }
  .section-7{
    margin-top: 50px;
  }
  .section-7 .footnote-text{
    font-size: 14px;
    line-height: 16px;
    margin-top: 7px;
  }
  footer{
    flex-wrap: wrap;
    padding-bottom: 50px;
  }
  footer .footer-logo{
    width: 130px;
    height: 34px;
  }
  footer .footer-text{
    font: 12px/14px "Montserrat", Arial, sans-serif;
    margin-left: 20px;
  }
  footer .footer-logo{
    margin: 10px 0;
  }
  footer .about{
    margin-left: 30px;
  }
  footer .approve-wrapper{
    margin: 30px auto 0;
  }
  footer .approve-wrapper span{
    text-align: center;
    font-size: 14px;
    line-height: 16px;
  }
}
@media (max-width: 900px){
  .page{
    padding: 78px 20px 0;
  }
  .section-1 .pack2-wrapper{
    right: 40px;
    width: 380px;
  }
  .section-2{
    padding-top: 80px;
  }
  .section-4{
    padding-top: 15px;
  }
  .section-6 .right-column{
    margin-left: 25px;
  }
  footer .footer-text{
    font: 11px/12px "Montserrat", Arial, sans-serif;
    margin-left: 10px;
  }
  footer .about{
    margin-left: 30px;
  }
}
@media (max-width: 800px){
  .page{
    padding: 155px 20px 0;
  }
  .section-title{
    font-size: 42px;
    line-height: 46px;
  }
  .section-text{
    font-size: 13px;
    line-height: 15px;
  }
  .section-text::v-deep(a){
    font: 400 14px/16px "Montserrat", Arial, sans-serif;
  }
  .section-1{
    height: 430px;
  }
  .section-1 .left-column{
    padding-top: 70px;
  }
  .section-1 .section-text::v-deep(a){
    font: 400 14px/18px "Montserrat", Arial, sans-serif;
  }
  .section-1 .pack1-image{
    width: 295px;
    height: 278px;
    margin: 60px 0 0 auto;
  }
  .section-1 .pack2-wrapper{
    bottom: 60px;
    right: 30px;
    width: 350px;
  }
  .section-1 .pack2-image{
    width: 91px;
    height: 72px;
  }
  .section-1 .pack2-text{
    margin-top: 12px;
  }
  .section-1 .pack3-wrapper{
    width: 350px;
  }
  .section-1 .pack3-image{
    width: 91px;
    height: 72px;
  }
  .section-1 .pack3-text{
    margin-top: 12px;
  }
  .section-2 .promo-image{
    width: 300px;
    height: 154px;
  }
  .section-2 .steps-wrapper-type-2{
    margin-top: 15px;
  }
  .section-2 .step-wrapper{
    height: 280px;
  }
  .section-2 .step1-image{
    width: 150px;
    height: 135px;
  }
  .section-2 .step2-image{
    width: 155px;
    height: 150px;
  }
  .section-2 .step3-image{
    width: 150px;
    height: 153px;
  }
  .section-2 .step4-image{
    width: 150px;
    height: 163px;
  }
  .section-2 .step5-image{
    width: 158px;
    height: 158px;
  }
  .section-3 .old-woman-wrapper{
    width: 295px;
    height: 350px;
    transform: scale(0.5);
  }
  .section-4 .section-text1{
    font-size: 20px;
    line-height: 20px;
  }
  .section-4 .feature-wrapper{
    height: 210px;
  }
  .section-4 .feature-image{
    width: 134px;
    height: 134px;
  }
  .section-4 .feature2-image{
    width: 156px;
    height: 134px;
  }
  .section-4 .feature-text{
    font-size: 13px;
    line-height: 15px;
    height: 50px;
  }
  .section-5 .point-text{
    font-size: 13px;
  }
  .section-5 .pack-dosage{
    width: 200px;
    height: 115px;
  }
  .section-5 .pack-dosage-text{
    margin-top: 20px;
    font-size: 13px;
    line-height: 15px;
  }
  .section-6 .section-caption{
    font-size: 22px;
  }
  .section-6 .section-text{
    font-size: 13px;
    line-height: 15px;
  }
  .section-7{
    margin-top: 40px;
  }
  .section-7 .footnote-text{
    font-size: 13px;
    line-height: 15px;
  }
  footer{
    flex-direction: column;
    flex-wrap: nowrap;
  }
  footer .footer-navigation-wrapper{
    margin-top: 15px;
  }
  footer .footer-navigation-wrapper .about{
    margin-left: 0;
  }
}
@media (max-width: 700px){
  .page{
    padding: 206px 20px 0;
  }
  .section-title{
    text-align: center;
  }
  .section-1{
    flex-direction: column;
    height: auto;
  }
  .section-1 .left-column,
  .section-1 .right-column{
    width: 100%;
  }
  .section-1 .left-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
  }
  .section-1 .right-column{
    position: relative;
  }
  .section-1 .section-text{
    width: 70%;
  }
  .section-1 .pack1-image{
    margin: 50px 0 0 0;
  }
  .section-1 .pack2-wrapper{
    width: 300px;
    bottom: 200px;
  }
  .section-1 .pack3-wrapper{
    width: 300px;
    bottom: 100px;
  }
  .section-2{
    padding-top: 30px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .section-2 .left-column .section-text{
    text-align: center;
    padding: 0 40px;
  }
  .section-2 .left-column,
  .section-2 .right-column{
    width: 100%;
  }
  .section-2 .promo-image{
    margin: 30px auto 0;
  }
  .section-2 .section-title2{
    margin-top: 40px;
  }
  .section-3{
    flex-direction: column;
  }
  .section-3 .left-column,
  .section-3 .right-column{
    width: 100%;
  }
  .section-3 .left-column{
    padding-top: 30px;
  }
  .section-3 .left-column .main-button{
    margin: 30px auto 0;
  }
  .section-3 .right-column{
    padding: 0;
  }
  .section-3 .old-woman-wrapper{
    margin: 0 auto;
  }
}
@media (max-width: 650px){
  .section-1 .pack1-image{
    margin: 50px auto 0;
  }
  .section-1 .pack2-wrapper{
    position: static;
    width: 100%;
    margin: 0 auto;
    padding: 0 100px;
  }
  .section-1 .pack3-wrapper{
    position: static;
    width: 100%;
    margin: 0 auto;
    padding: 0 100px;
  }
  .section-4 .section-text1{
    text-align: center;
  }
  .section-4 .section-text2{
    width: 100%;
    text-align: center;
  }
  .section-6 .right-column{
    margin-left: 30px;
    padding-top: 5px;
  }
  footer .footer-navigation-wrapper{
    display: flex;
    flex-direction: column;
  }
  footer .footer-text{
    font: 13px/26px "Montserrat", Arial, sans-serif;
    text-align: center;
    margin-left: 0;
  }
}
@media (max-width: 600px){
  .section-1 .section-text{
    padding: 0 40px;
    text-align: center;
    width: unset;
  }
  .section-1 .left-column .section-text{
    margin-top: 30px;
  }
  .section-1 .start-game-button{
    margin: 30px auto 0;
  }
  .section-1 .pack2-wrapper{
    padding: 0 60px;
  }
  .section-1 .pack3-wrapper{
    padding: 0 60px;
  }
  .section-3 .old-woman-wrapper{
    height: 280px;
  }
  .section-4 .section-text1{
    margin-top: 60px;
  }
  .section-5 .line-wrapper{
    margin-right: 50px;
  }
  .section-6{
    flex-direction: column;
    justify-content: center;
  }
  .section-6 .left-column img{
    display: flex;
    margin: 0 auto;
  }
  .section-6 .right-column{
    margin-left: 0;
  }
  .section-6 .section-caption{
    text-align: center;
  }
  .section-6 .section-text{
    text-align: center;
    padding: 0;
  }
}
@media (max-width: 550px){
  .page{
    padding: 78px 20px 0;
  }
}
@media (max-width: 500px){
  .section-1 .pack2-wrapper{
    padding: 0 10px;
  }
  .section-1 .pack3-wrapper{
    padding: 0 10px;
  }
  .section-2 .steps-wrapper-type-2 .step-wrapper:first-of-type{
    height: 260px;
  }
}
@media (max-width: 450px){
  .page{
    padding: 78px 15px 0;
  }
  .section-1 .section-text{
    padding: 0 0;
  }
  .section-1 .left-column .section-text{
    margin-top: 35px;
  }
  .section-3 .old-woman-wrapper{
    width: 180px;
  }
  .section-5 .line-wrapper{
    margin-right: 20px;
    width: 30%;
  }
  .section-5 .line-wrapper:before{
    left: 13px;
    width: 3px;
  }
  .section-5 .point{
    width: 25px;
    height: 25px;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .section-5 .point-text{
    line-height: 16px;
  }
}
</style>
