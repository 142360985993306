import { levels } from '@/config/game';

export default {
  namespaced: true,
  state: () => ({
    ...levels,
  }),
  mutations: {},
  actions: {},
  getters: {},
};
