<template>
  <div class="page">
    <header>
      <img
        src="../assets/media/pages/main/main-logo.svg"
        alt="Logo"
        class="header-logo"
      >
    </header>
    <main class="main-wrapper">
      <h2 class="title">
        {{ $t('pages.intro.text1') }}
      </h2>
      <span
        class="description"
        v-html="$t('pages.intro.text2')"
      />
      <div class="buttons-wrapper">
        <button
          class="button-answer"
          :class="{selected: selectedIndex === 0}"
          @click="selectItem(0)"
        >
          {{ $t('pages.intro.button1') }}
        </button>
        <button
          class="button-answer"
          :class="{selected: selectedIndex === 1}"
          @click="selectItem(1)"
        >
          {{ $t('pages.intro.button2') }}
        </button>
        <button
          class="button-answer"
          :class="{selected: selectedIndex === 2}"
          @click="selectItem(2)"
        >
          {{ $t('pages.intro.button3') }}
        </button>
        <button
          class="main-button confirm-button"
          :class="{active: selectedIndex !== null}"
          @click="confirm"
        >
          {{ $t('pages.intro.confirm') }}
        </button>
      </div>
    </main>
    <v-flowerpot-animation class="flowerpot-animation" />
    <v-preload-hidden-images
      :images="[
        require('../assets/media/pages/intro/button-hover.svg'),
        require('../assets/media/pages/intro/button-active.svg')
      ]"
    />
  </div>
</template>

<script>
import vFlowerpotAnimation from '@/components/FlowerpotAnimation.vue';
import { REDIRECT_URL_FOR_PATIENT } from '@/config/common.json';

export default {
  name: 'Intro',
  components: {
    vFlowerpotAnimation,
  },
  data() {
    return {
      selectedIndex: null,
    };
  },
  methods: {
    selectItem(index) {
      if (this.selectedIndex === index) this.selectedIndex = null;
      else this.selectedIndex = index;
    },
    confirm() {
      if (this.selectedIndex === 2) {
        window.open(REDIRECT_URL_FOR_PATIENT, '_self');
      } else {
        this.goToPageMain();
      }
      this.selectedIndex = null;
    },
    goToPageMain() {
      this.$router.push('main').catch(() => {
      });
    },
  },
};
</script>

<style scoped>
.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 764px;
  background: url("../assets/media/pages/intro/bg.svg") center / cover no-repeat;
}
header{
  width: 100%;
  box-sizing: border-box;
  padding: 30px 60px 0;
}
header .header-logo{
  width: 170px;
  height: 80px;
}
.main-wrapper{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 568px;
  margin-top: 20px;
}
.description{
  margin-top: 20px;
  text-align: center;
}
.buttons-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 40px;
  width: 380px;
}
.button-answer{
  margin-bottom: 9px;
  padding: 14px 25px;
  border-radius: 50px;
  border: 10px solid #dee8ff;
  background: #ffffff;
  font-weight: 700;
  color: var(--primary-color);
  text-align: left;
}
.button-answer.selected{
  background: var(--secondary-color-3);
  color: #ffffff;
}
.confirm-button{
  width: 380px;
  height: 98px;
  margin-top: 30px;
  background: url("../assets/media/pages/intro/button-default.svg") no-repeat center/contain;
  pointer-events: none;
  opacity: 0.7;
}
.confirm-button.active{
  opacity: 1;
  pointer-events: auto;
}
.confirm-button.active:hover{
  background: url("../assets/media/pages/intro/button-hover.svg") no-repeat center/contain;
}
.confirm-button.active:active{
  background: url("../assets/media/pages/intro/button-active.svg") no-repeat center/contain;
}
.flowerpot-animation{
  bottom: 5%;
  left: 15%;
}
@media (max-width: 1450px){
  .flowerpot-animation{
    bottom: 0;
    left: 9%;
    transform: scale(0.8);
  }
}
@media (max-width: 1350px){
  .page{
    min-height: 1200px;
  }
  header{
    text-align: center;
  }
  .flowerpot-animation{
    position: static;
  }
}
@media (max-width: 650px){
  .main-wrapper{
    width: 500px;
  }
}
@media (max-width: 500px){
  .title{
    font-size: 36px;
  }
  .main-wrapper{
    width: 430px;
  }
  .description{
    font-size: 16px;
  }
}
@media (max-width: 430px){
  header{
    padding: 10px 60px 0;
  }
  .title{
    font-size: 30px;
  }
  .main-wrapper{
    width: 350px;
    margin-top: 10px;
  }
  .description{
    margin-top: 10px;
    font-size: 16px;
  }
  .buttons-wrapper{
    width: 340px;
  }
  .button-answer{
    font-size: 16px;
  }
  .confirm-button{
    width: 340px;
    height: 88px;
    margin-top: 0px;
  }
}
</style>
