<template>
  <div
    class="page"
    :style="{transform: `scale(${scaleValue})`}"
  >
    <v-header
      class="game-header"
      disable-info-button
    >
      <v-score
        ref="score"
        class="score"
      />
    </v-header>
    <h2 class="title">
      {{ $t('pages.conclusion.thankYouMessage') }}
    </h2>
    <div class="rank-box">
      <img
        class="rank-image"
        :src="rankImage"
        alt="rank-image"
      >
      <div class="left-column">
        <p
          v-show="user.rankIndex === 0"
          class="rank-short-text"
        >
          {{ $t('pages.conclusion.rankShortText') }}
        </p>
        <p class="rank-text">
          {{ rankText }}
        </p>
        <div class="share-button-wrapper">
          <button
            class="share-button"
            @click="shareResult"
          />
          <span class="share-text">{{ $t('pages.conclusion.shareText') }}</span>
        </div>
      </div>
    </div>
    <p class="score-value-wrapper">
      <span class="score-value-text1"> {{ $t('pages.conclusion.scoreValueText1') }} </span>
      <span class="score-value"> {{ user.scoreValue }} </span>
      <span class="score-value-description"> {{ scoreValueDescription }} </span><br>
      <span
        v-if="!showedPromoCodeButton"
        class="score-value-text2"
      > {{
        $t('pages.conclusion.scoreValueText2')
      }} </span>
    </p>
    <div
      v-if="!showedPromoCodeButton"
      class="restart-button-wrapper"
    >
      <button
        class="restart-button"
        @click="restartGame"
      />
      <span
        class="restart-button-title"
        v-html="$t('pages.conclusion.restartButtonTitle')"
      />
    </div>
    <button
      v-if="showedPromoCodeButton"
      class="get-promo-code-button main-button"
      @click="goToPromoCode"
    >
      {{ $t('pages.conclusion.getPromoCode') }}
    </button>
    <v-flowerpot-animation class="flowerpot-animation" />
    <v-popup-socials-sharing
      v-model:opened="isPopupSocialsOpened"
      :score-value="user.scoreValue"
      :rank-text="rankText"
      :rank-index="user.rankIndex"
      :socials-options="socialsConfig"
      class="popup-socials-sharing"
    />
    <v-preload-hidden-images
      :images="[
        require('../assets/media/pages/conclusion/get-promo-code-button-hover.svg'),
        require('../assets/media/pages/conclusion/get-promo-code-button-active.svg'),
      ]"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { numberEnd } from '@/utils';
import gameScale from '@/mixins/game-scale';
import vHeader from '@/components/GameHeader.vue';
import vScore from '@/components/Score.vue';
import socialsConfig from '@/config/socials-sharing';
import vFlowerpotAnimation from '@/components/FlowerpotAnimation.vue';
import vPopupSocialsSharing from '@/components/popups/PopupSocialsSharing.vue';
import rank1Icon from '@/assets/media/pages/conclusion/rank1-icon.svg';
import rank2Icon from '@/assets/media/pages/conclusion/rank2-icon.svg';
import rank3Icon from '@/assets/media/pages/conclusion/rank3-icon.svg';
import rank4Icon from '@/assets/media/pages/conclusion/rank4-icon.svg';
import rank5Icon from '@/assets/media/pages/conclusion/rank5-icon.svg';
import ranks from '@/config/game/ranks.json';

export default {
  name: 'Conclusion',
  components: {
    vHeader,
    vPopupSocialsSharing,
    vFlowerpotAnimation,
    vScore,
  },
  mixins: [gameScale],
  data: () => ({
    isPopupSocialsOpened: false,
    socialsConfig,
  }),
  computed: {
    ...mapState(['user']),
    scoreValueDescription() {
      return numberEnd(this.user.scoreValue, [
        this.$t('pages.conclusion.scoreTextType1'),
        this.$t('pages.conclusion.scoreTextType2'),
        this.$t('pages.conclusion.scoreTextType3'),
      ]);
    },
    rankImage() {
      const rankIcons = [
        rank1Icon,
        rank2Icon,
        rank3Icon,
        rank4Icon,
        rank5Icon,
      ];
      return rankIcons[this.user.rankIndex];
    },
    rankText() {
      return ranks[this.user.rankIndex].name;
    },
    showedPromoCodeButton() {
      return this.user.rankIndex === 4;
    },
  },
  mounted() {
    const { rank } = this.$router.currentRoute.value.query;
    if (rank) {
      const values = [15, 25, 35, 45, 55];
      const score = values[rank - 1];
      this.$store.dispatch('user/setScore', score);
    }
    this.$refs.score.setScoreValue(this.user.scoreValue);
  },
  methods: {
    shareResult() {
      this.isPopupSocialsOpened = true;
    },
    restartGame() {
      this.$store.commit('user/reset');
      this.goToPageGame();
    },
    goToPromoCode() {
      this.$router.push('promo-code').catch(() => {
      });
    },
    goToPageGame() {
      this.$router.push('game').catch(() => {
      });
    },
  },
};
</script>

<style scoped>
.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 1920px;
  height: 944px;
  padding: 0 55px;
  background: url("../assets/media/pages/conclusion/bg.svg") center/cover no-repeat;
  transform-origin: center top;
}
.title{
  margin-top: 80px;
  text-align: center;
}
.rank-box{
  display: flex;
  align-items: center;
  width: 678px;
  height: 317px;
  padding: 0 20px;
  margin: 38px auto 0;
  background: url("../assets/media/pages/conclusion/conclusion-box.svg") no-repeat center/contain;
}
.rank-image{
  width: 267px;
  height: 217px;
}
.left-column{
  width: 58%;
}
.rank-short-text{
  font-size: 18px;
}
.rank-text{
  font: 28px/30px "Dela Gothic One", Arial, sans-serif;
  color: var(--secondary-color-3);
}
.share-button-wrapper{
  display: flex;
  align-items: center;
  margin-top: 27px;
}
.share-button{
  width: 77px;
  height: 77px;
  margin-right: 6px;
  background: url("../assets/media/pages/conclusion/share-button.svg") no-repeat center/contain;
}
.share-button:hover{
  opacity: 0.9;
}
.share-text{
  font: 28px/27px "Dela Gothic One", Arial, sans-serif;
}
.score-value-wrapper{
  margin-top: 28px;
  line-height: 24px;
  text-align: center;
}
.restart-button-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}
.restart-button{
  width: 77px;
  height: 77px;
  margin-right: 20px;
  background: url("../assets/media/common/restart-button.svg") no-repeat center/contain;
}
.restart-button:hover{
  opacity: 0.9;
}
.restart-button-title{
  width: 360px;
  font: 28px/30px "Dela Gothic One", Arial, sans-serif;
}
.get-promo-code-button{
  width: 460px;
  height: 98px;
  margin: 60px auto 0;
  background: url("../assets/media/pages/conclusion/get-promo-code-button-default.svg") no-repeat center/contain;
}
.get-promo-code-button:hover{
  background: url("../assets/media/pages/conclusion/get-promo-code-button-hover.svg") no-repeat center/contain;
}
.get-promo-code-button:active{
  background: url("../assets/media/pages/conclusion/get-promo-code-button-active.svg") no-repeat center/contain;
}
.flowerpot-animation{
  bottom: 5%;
  left: 15%;
}
@media (max-width: 1024px){
  .page{
    padding: 20px 30px 0;
    width: 100%;
    height: 1150px;
    min-height: 764px;
    overflow: unset;
    transform: unset !important;
  }
  .page .popup{
    position: absolute;
  }
  .page .popup::v-deep(.popup-content){
    top: 100px;
    transform: translate(-50%, 0%);
  }
  .game-header{
    padding: 0 0 10px 0;
  }
  .game-header ::v-deep(.back-button){
    width: 55px;
    height: 55px;
  }
  .game-header ::v-deep(.back-button-text){
    font-size: 14px;
    margin: 0 auto 0 11px;
  }
  .game-header ::v-deep(.score-wrapper){
    width: 160px;
    height: 60px;
  }
  .game-header ::v-deep(.title){
    font-size: 12px;
    margin: 14px 0 0 19px;
  }
  .game-header ::v-deep(.text){
    font-size: 14px;
  }
  .title{
    font-size: 36px;
  }
  .rank-box{
    width: 460px;
    height: 217px;
    margin: 70px auto 25px;
  }
  .rank-box .rank-short-text{
    font-size: 13px;
    line-height: 26px;
  }
  .rank-box .rank-text{
    font-size: 18px;
    line-height: 22px;
  }
  .rank-box .share-button{
    width: 55px;
    height: 55px;
  }
  .rank-box .share-text{
    font-size: 18px;
  }
  .rank-box .rank-image{
    width: 187px;
    height: auto;
  }
  .rank-box .share-button-wrapper{
    margin-top: 20px;
  }
  .restart-button{
    width: 55px;
    height: 55px;
  }
  .restart-button-title{
    width: 230px;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 5px;
  }
  .get-promo-code-button{
    flex-shrink: 0;
    width: 360px;
    height: 78px;
    font-size: 24px;
  }
  .flowerpot-animation{
    width: 180px;
    flex-shrink: 0;
    position: static;
    transform: scale(0.8);
  }
  .popup-socials-sharing::v-deep(.popup-content){
    width: 620px;
  }
  .popup-socials-sharing::v-deep(.title){
    margin: 15px 0;
    font-size: 24px;
    line-height: 30px;
  }
  .popup-socials-sharing::v-deep(.socials){
    height: 100px;
  }
  .popup-socials-sharing::v-deep(.social-icon){
    width: 60px;
    height: 60px;
  }
  .popup-socials-sharing::v-deep(.social.vk){
    font-size: 22px;
  }
  .popup-socials-sharing::v-deep(.share-text1 span),
  .popup-socials-sharing::v-deep(.share-text2 span){
    font-size: 16px;
  }
  .popup-socials-sharing::v-deep(span.score-value),
  .popup-socials-sharing::v-deep(span.rank-text){
    font-size: 22px;
  }
  .popup-socials-sharing::v-deep(.close-popup-button){
    width: 300px;
    height: 78px;
    margin: 10px auto 10px;
    font-size: 24px;
  }
}
@media (max-width: 650px){
  .page{
    padding: 20px 20px 0;
    height: 1050px;
  }
  .title{
    margin-top: 60px;
    font-size: 24px;
  }
  .rank-box{
    width: 400px;
    height: 187px;
    margin: 50px auto 25px;
  }
  .rank-box .rank-image{
    width: 147px;
    height: auto;
    margin: 70px auto 60px;
  }
  .get-promo-code-button{
    width: 290px;
    height: 62px;
    font-size: 16px;
  }
  .popup-socials-sharing::v-deep(.popup-content){
    width: 410px;
  }
  .popup-socials-sharing::v-deep(.border-block){
    padding: 0 5px 10px 5px;
  }
}
@media (max-width: 450px){
  .page{
    height: 950px;
  }
  .game-header ::v-deep(.back-button){
    width: 45px;
    height: 45px;
  }
  .game-header ::v-deep(.back-button-text){
    font-size: 12px;
    margin: 0 auto 0 11px;
  }
  .game-header ::v-deep(.score-wrapper){
    width: 110px;
    height: 45px;
  }
  .game-header ::v-deep(.title){
    font-size: 10px;
    line-height: 1.2;
    margin: 10px 0 0 19px;
  }
  .game-header ::v-deep(.text){
    font-size: 11px;
    line-height: 1.2;
  }
  .title{
    font-size: 24px;
  }
  .rank-box{
    width: 340px;
    height: 157px;
  }
  .rank-box .rank-short-text{
    font-size: 12px;
    line-height: 14px;
  }
  .rank-box .rank-text{
    font-size: 16px;
  }
  .rank-box .share-button{
    width: 45px;
    height: 45px;
  }
  .rank-box .share-text{
    font-size: 16px;
  }
  .rank-box .rank-image{
    width: 127px;
    height: auto;
  }
  .score-value-wrapper{
    line-height: 20px;
  }
  .score-value-wrapper span{
    font-size: 16px;
  }
  .restart-button{
    width: 45px;
    height: 45px;
  }
  .restart-button-title{
    font-size: 16px;
  }
  .flowerpot-animation{
    transform: scale(0.6);
  }
  .popup-socials-sharing::v-deep(.popup-content){
    width: 340px;
  }
  .popup-socials-sharing::v-deep(.title){
    margin: 10px 0;
    font-size: 18px;
    line-height: 22px;
  }
  .popup-socials-sharing::v-deep(.socials){
    height: 70px;
  }
  .popup-socials-sharing::v-deep(.social-icon){
    width: 50px;
    height: 50px;
  }
  .popup-socials-sharing::v-deep(.social.vk){
    font-size: 18px;
  }
  .popup-socials-sharing::v-deep(.share-text1 span),
  .popup-socials-sharing::v-deep(.share-text2 span){
    font-size: 14px;
  }
  .popup-socials-sharing::v-deep(span.score-value),
  .popup-socials-sharing::v-deep(span.rank-text){
    font-size: 18px;
  }
  .popup-socials-sharing::v-deep(.close-popup-button){
    width: 260px;
    height: 68px;
    font-size: 18px;
  }
}
</style>
