<template>
  <div class="timer-wrapper">
    <span class="title">{{ $t('components.gameHeader.timerTitle') }}</span>
    <span class="text">{{ displayedCurrentTime }}</span>
  </div>
</template>

<script>
export default {
  name: 'Timer',
  props: {
    time: {
      type: Number,
      default: 10,
    },
  },
  emits: ['percent-time-remaining'],
  data() {
    return {
      timerID: null,
      currentTime: null,
      displayedCurrentTime: null,
    };
  },
  watch: {
    time: {
      handler() {
        this.initTimer();
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    clearInterval(this.timerID);
  },
  methods: {
    initTimer() {
      this.currentTime = this.time;
      this.setDisplayedCurrentTime();
      this.$emit('percent-time-remaining', this.getPercentTimeRemaining());
    },
    setDisplayedCurrentTime() {
      let minutes = this.currentTime / 60;
      let seconds = this.currentTime % 60;
      if (minutes < 10) minutes = `0${Math.trunc(minutes)}`;
      if (seconds < 10) seconds = `0${seconds}`;
      this.displayedCurrentTime = `${Math.trunc(minutes)}:${seconds}`;
    },
    startTimer() {
      if (this.timerID) return;
      this.timerID = setInterval(() => {
        this.setDisplayedCurrentTime();
        this.$emit('percent-time-remaining', this.getPercentTimeRemaining());
        if (this.currentTime <= 0) clearInterval(this.timerID);
        this.currentTime -= 1;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerID);
      this.timerID = null;
    },
    getPercentTimeRemaining() {
      return 100 - (this.currentTime / this.time) * 100;
    },
  },
};
</script>

<style scoped>
.timer-wrapper{
  width: 180px;
  height: 80px;
  background: url("../assets/media/components/timer/timer.svg") no-repeat center/contain;
}
.title,
.text{
  display: block;
  margin: 19px 0 0 19px;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  color: var(--secondary-color-6);
}
.title{
  font-weight: 800;
}
.text{
  font-family: "Dela Gothic One", Arial, sans-serif;
  margin-top: 0;
  font-size: 20px;
}
</style>
