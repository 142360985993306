<template>
  <div
    v-if="animate"
    class="character"
    :class="{animate, hide}"
    @transitionend="onTransitionEnd"
  >
    <div class="body" />
    <div class="head" />
    <div class="bag" />
    <div class="hand" />
  </div>
</template>

<script>
import mixin from './template/mixin';

export default {
  name: 'Man2Type1',
  mixins: [mixin],
};
</script>

<style scoped>
@import "./template/base.css";
.character{
  top: 291px;
  right: 0;
  width: 200px;
  height: 590px;
  transform: scale(1.15) translateX(120%);
}
.character.animate{
  animation: showCharacter var(--transition-duration) var(--transition-delay) forwards;
}
.body{
  position: absolute;
  top: 0;
  left: -38px;
  width: 205px;
  height: 584px;
  background: url("../../assets/media/components/characters/man-2-type-1/body.svg") no-repeat center/contain;
}
.head{
  position: absolute;
  top: -88px;
  left: 14px;
  width: 124px;
  height: 124px;
  background: url("../../assets/media/components/characters/man-2-type-1/head.svg") no-repeat center/contain;
  transform-origin: bottom;
  animation: rotateInLeft 7s 0.5s infinite;
}
.hand{
  position: absolute;
  top: 94px;
  left: 141px;
  width: 37px;
  height: 281px;
  background: url("../../assets/media/components/characters/man-2-type-1/hand.svg") no-repeat center/contain;
}
.bag{
  position: absolute;
  top: 358px;
  left: 113px;
  width: 92px;
  height: 98px;
  background: url("../../assets/media/components/characters/man-2-type-1/bag.svg") no-repeat center/contain;
  transform-origin: top;
  animation: rotateInRight 7s 0.5s infinite;
}
@keyframes showCharacter{
  0%{
    transform: translateX(120%) scale(1.15);
  }
  100%{
    transform: translateX(-105%) scale(1.15);
  }
}
@keyframes rotateInRight{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(5deg);
  }
  100%{
    transform: rotate(0);
  }
}
@keyframes rotateInLeft{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0);
  }
}
</style>
