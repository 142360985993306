<template>
  <div
    class="scroll-to-top"
    :class="{show}"
  >
    <button @click="scrollToTop" />
  </div>
</template>

<script>
const SCROLL_Y_FOR_SHOW_BUTTON = 200;
export default {
  name: 'ScrollToTop',
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler.bind(this));
  },
  methods: {
    scrollHandler() {
      this.show = window.scrollY >= SCROLL_Y_FOR_SHOW_BUTTON;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.scroll-to-top{
  position: fixed;
  bottom: 80px;
  right: 80px;
  width: 119px;
  height: 125px;
  background: url("../assets/media/components/scroll-to-top/scrollup-button.png") no-repeat center/contain;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.7s;
}
.scroll-to-top.show{
  pointer-events: auto;
  opacity: 1;
}
.scroll-to-top button{
  opacity: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1700px){
  .scroll-to-top{
    bottom: 72px;
    right: 72px;
    width: 107px;
    height: 112px;
  }
}
@media (max-width: 1400px){
  .scroll-to-top{
    bottom: 64px;
    right: 64px;
    width: 95px;
    height: 100px;
  }
}
@media (max-width: 1100px){
  .scroll-to-top{
    bottom: 56px;
    right: 56px;
    width: 84px;
    height: 88px;
  }
}
@media (max-width: 800px){
  .scroll-to-top{
    bottom: 48px;
    right: 48px;
    width: 72px;
    height: 75px;
  }
}
@media (max-width: 500px){
  .scroll-to-top{
    bottom: 40px;
    right: 40px;
    width: 60px;
    height: 63px;
  }
}
@media (max-width: 400px){
  .scroll-to-top{
    bottom: 36px;
    right: 36px;
    width: 53px;
    height: 56px;
  }
}
@media (max-width: 300px){
  .scroll-to-top{
    bottom: 32px;
    right: 32px;
    width: 48px;
    height: 50px;
  }
}
</style>
