<template>
  <div
    v-if="animate"
    class="character"
    :class="{animate, hide}"
    @transitionend="onTransitionEnd"
  >
    <div class="body" />
    <div class="head" />
    <div class="hand" />
  </div>
</template>

<script>
import mixin from './template/mixin';

export default {
  name: 'OldMan1Type2',
  mixins: [mixin],
};
</script>

<style scoped>
@import "./template/base.css";
.character{
  top: 427px;
  right: 0;
  width: 380px;
  height: 420px;
  transform: scale(1.4) translateX(120%);
}
.character.animate{
  animation: showCharacter var(--transition-duration) var(--transition-delay) forwards;
}
.body{
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
  height: 411px;
  background: url("../../assets/media/components/characters/old-man-1-type-2/body.svg") no-repeat center/contain;
}
.head{
  position: absolute;
  top: -92px;
  left: 99px;
  width: 173px;
  height: 166px;
  background: url("../../assets/media/components/characters/old-man-1-type-2/head.svg") no-repeat center/contain;
  transform-origin: bottom;
  animation: rotateInRight 7s 0.5s infinite;
}
.hand{
  position: absolute;
  top: 50px;
  left: 203px;
  width: 169px;
  height: 246px;
  background: url("../../assets/media/components/characters/old-man-1-type-2/hand.svg") no-repeat center/contain;
  transform-origin: top;
  animation: rotateInRight 7s 0.5s infinite;
}
@keyframes showCharacter{
  0%{
    transform: translateX(120%) scale(1.4);
  }
  100%{
    transform: translateX(-40%) scale(1.4);
  }
}
@keyframes rotateInRight{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(5deg);
  }
  100%{
    transform: rotate(0);
  }
}
@keyframes rotateInLeft{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0);
  }
}
</style>
