<template>
  <div class="score-wrapper">
    <span class="title">{{ $t('components.gameHeader.scoreTitle') }}</span>
    <span class="text">{{ scoreValue }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Score',
  props: {
    scoreUpdateSpeed: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      scoreValue: 0,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    setScoreValue(value) {
      if (value > this.scoreValue) {
        const intervalID = setInterval(() => {
          this.scoreValue += 1;
          if (value === this.scoreValue) clearInterval(intervalID);
        }, this.scoreUpdateSpeed);
      }
      if (value < this.scoreValue) {
        const intervalID = setInterval(() => {
          this.scoreValue -= 1;
          if (value === this.scoreValue) clearInterval(intervalID);
        }, this.scoreUpdateSpeed);
      }
    },
  },
};
</script>

<style scoped>
.score-wrapper{
  width: 180px;
  height: 80px;
  background: url("../assets/media/components/score/score.svg") no-repeat center/contain;
}
.title,
.text{
  display: block;
  margin: 19px 0 0 19px;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  color: var(--secondary-color-6);
}
.title{
  font-weight: 800;
}
.text{
  font-family: "Dela Gothic One", Arial, sans-serif;
  margin-top: 0;
  font-size: 20px;
}
</style>
