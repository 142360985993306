import { isTouchDevice } from '@/utils';

const PAGE_WIDTH = 1920;

export default {
  data() {
    return {
      scaleValue: 1,
    };
  },
  created() {
    this.setPageScale();
    if (!isTouchDevice()) window.addEventListener('resize', this.setPageScale.bind(this));
    window.addEventListener('orientationchange', this.setPageScale.bind(this));
  },
  methods: {
    setPageScale() {
      this.setScaleValue();
      setTimeout(this.setScaleValue.bind(this), 150);
    },
    setScaleValue() {
      this.scaleValue = document.body.getBoundingClientRect().width / PAGE_WIDTH;
    },
  },
};
