<template>
  <div
    v-if="animate"
    class="character"
    :class="{animate, hide}"
    @transitionend="onTransitionEnd"
  >
    <div class="body" />
    <div class="head" />
    <div class="cat" />
    <div class="cat-ear" />
    <div class="pocket" />
    <div class="hand" />
  </div>
</template>

<script>
import mixin from './template/mixin';

export default {
  name: 'OldWoman1Type4',
  mixins: [mixin],
};
</script>

<style scoped>
@import "./template/base.css";
.character{
  top: 332px;
  right: 0;
  width: 430px;
  height: 590px;
  transform: translateX(100%);
}
.character.animate{
  animation: showCharacter var(--transition-duration) var(--transition-delay) forwards;
}
.body{
  position: absolute;
  top: 0;
  left: 0;
  width: 414px;
  height: 586px;
  background: url("../../assets/media/components/characters/old-woman-1-type-4/body.svg") no-repeat center/contain;
}
.head{
  position: absolute;
  top: -144px;
  left: 20px;
  width: 248px;
  height: 236px;
  background: url("../../assets/media/components/characters/old-woman-1-type-4/head.svg") no-repeat center/contain;
  transform-origin: bottom;
  animation: rotateInRight 7s 0.5s infinite;
}
.cat{
  position: absolute;
  top: -51px;
  left: 202px;
  width: 121px;
  height: 155px;
  background: url("../../assets/media/components/characters/old-woman-1-type-4/cat-body.svg") no-repeat center/contain;
}
.cat-ear{
  position: absolute;
  top: -45px;
  left: 297px;
  width: 54px;
  height: 34px;
  background: url("../../assets/media/components/characters/old-woman-1-type-4/cat-ear.svg") no-repeat center/contain;
  transform-origin: left;
  animation: rotateInLeft 5s 0.5s infinite;
}
.hand{
  position: absolute;
  top: 172px;
  left: 303px;
  width: 81px;
  height: 186px;
  background: url("../../assets/media/components/characters/old-woman-1-type-4/hand.svg") no-repeat center/contain;
}
.pocket{
  position: absolute;
  top: 300px;
  left: 217px;
  width: 210px;
  height: 220px;
  background: url("../../assets/media/components/characters/old-woman-1-type-4/pocket.svg") no-repeat center/contain;
  transform-origin: top;
  animation: rotateInRight 7s 0.5s infinite;
}
@keyframes showCharacter{
  0%{
    transform: translateX(100%);
  }
  100%{
    transform: translateX(-24%);
  }
}
@keyframes rotateInRight{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(5deg);
  }
  100%{
    transform: rotate(0);
  }
}
@keyframes rotateInLeft{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0);
  }
}
</style>
