import ranks from '@/config/game/ranks.json';

export default {
  namespaced: true,
  state: () => ({
    scoreValue: 0,
    rankIndex: 0,
  }),
  mutations: {
    incrementScoreValue(state, payload) {
      state.scoreValue += payload;
    },
    setRangValue(state) {
      state.rankIndex = ranks.findIndex((rank) => rank.min <= state.scoreValue && state.scoreValue <= rank.max) || 0;
    },
    reset(state) {
      state.scoreValue = 0;
      state.rankIndex = 0;
    },
  },
  actions: {
    setScore({ commit, state }, payload) {
      commit('incrementScoreValue', payload);
      commit('setRangValue', payload);
    },
  },
  getters: {},
};
