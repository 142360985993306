<template>
  <div
    class="popup popup-level-success"
    :class="{opened}"
    @transitionend="hideContent"
  >
    <canvas
      id="canvasConfetti"
      class="canvas-confetti"
    />
    <div
      v-show="shownContent"
      class="popup-content"
    >
      <div class="popup-content-border-wrapper">
        <div class="popup-content-border" />
      </div>
      <div class="border-block">
        <h2 class="title">
          {{ $t('components.popupLevelSuccess.title') }}
        </h2>
        <p class="text1">
          {{ $t('components.popupLevelSuccess.text1') }}
        </p>
        <p class="score-value-wrapper">
          <span class="score-value"> {{ internalScoreValue }}  </span>
          <span class="score-value-description"> {{ scoreValueDescription }}. </span>
        </p>
        <p class="text2">
          <span class="level-value-description1"> {{ $t('components.popupLevelSuccess.text2') }}</span>
          <span class="level-value"> {{ levelValue }}  </span>
          <span class="level-value-description2"> {{ levelValueDescription }}</span>
        </p>
        <button
          class="main-button next-button"
          @click="goToNextLevel"
        >
          {{ $t('components.popupLevelSuccess.nextButtonTitle') }}
        </button>
      </div>
    </div>
    <v-preload-hidden-images
      :images="[
        require('../../assets/media/components/popup-level-success/next-button-hover.svg'),
        require('../../assets/media/components/popup-level-success/next-button-active.svg'),
      ]"
    />
  </div>
</template>

<script>
import ConfettiGenerator from 'confetti-js';
import { numberEnd } from '@/utils';

export default {
  name: 'PopupLevelSuccess',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    scoreValue: {
      type: Number,
      default: 50,
    },
    levelValue: {
      type: Number,
      default: 3,
    },
  },
  emits: ['update:opened', 'goto-next-level'],
  data() {
    return {
      shownContent: false,
      confetti: null,
      internalScoreValue: 0,
    };
  },
  computed: {
    scoreValueDescription() {
      return numberEnd(this.internalScoreValue, [
        this.$t('components.popupLevelSuccess.scoreTextType1'),
        this.$t('components.popupLevelSuccess.scoreTextType2'),
        this.$t('components.popupLevelSuccess.scoreTextType3'),
      ]);
    },
    levelValueDescription() {
      return numberEnd(this.levelValue, [
        this.$t('components.popupLevelSuccess.levelTextType1'),
        this.$t('components.popupLevelSuccess.levelTextType2'),
      ]);
    },
  },
  watch: {
    opened: {
      immediate: true,
      handler(value) {
        if (value) {
          this.internalScoreValue = this.scoreValue;
          this.showContent();
          this.initConfetti();
        }
      },
    },
  },
  methods: {
    closePopup() {
      this.$emit('update:opened', false);
    },
    showContent() {
      this.shownContent = true;
    },
    hideContent() {
      if (!this.opened) {
        this.shownContent = false;
        this.confetti.clear();
      }
    },
    initConfetti() {
      const settings = { target: 'canvasConfetti', max: 200, size: 1.5 };
      this.confetti = new ConfettiGenerator(settings);
      this.confetti.render();
    },
    goToNextLevel() {
      this.closePopup();
      this.$emit('goto-next-level');
    },
  },
};
</script>

<style scoped>
.popup-content{
  padding: 20px;
}
.canvas-confetti{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.border-block{
  padding: 0 55px 30px 25px;
  border: 4px solid var(--secondary-color-5);
}
.title{
  margin-top: 60px;
  text-align: center;
}
.text1{
  margin-top: 16px;
  font-size: 18px;
  text-align: center;
}
.score-value-wrapper{
  text-align: center;
  margin-top: 16px;
}
.score-value{
  font-size: 40px;
  font-weight: 700;
}
.score-value-description{
  margin-left: 10px;
}
.text2{
  margin-top: 16px;
  margin-bottom: 30px;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
}
.next-button{
  width: 460px;
  height: 100px;
  margin: 40px auto 0;
  background: url("../../assets/media/components/popup-level-success/next-button-defalut.svg") no-repeat center/contain;
}
.next-button:hover{
  background: url("../../assets/media/components/popup-level-success/next-button-hover.svg") no-repeat center/contain;
}
.next-button:active{
  background: url("../../assets/media/components/popup-level-success/next-button-active.svg") no-repeat center/contain;
}
</style>
