<template>
  <div
    v-if="animate"
    class="character"
    :class="{animate, hide}"
    @transitionend="onTransitionEnd"
  >
    <div class="body" />
    <div class="head" />
  </div>
</template>

<script>
import mixin from './template/mixin';

export default {
  name: 'Woman1Type3',
  mixins: [mixin],
};
</script>

<style scoped>
@import "./template/base.css";
.character{
  top: 373px;
  right: 0;
  width: 220px;
  height: 500px;
  transform: scale(1.2) translateX(120%);
}
.character.animate{
  animation: showCharacter var(--transition-duration) var(--transition-delay) forwards;
}
.body{
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  height: 495px;
  background: url("../../assets/media/components/characters/woman-1-type-3/body.svg") no-repeat center/contain;
}
.head{
  position: absolute;
  top: -89px;
  left: 23px;
  width: 142px;
  height: 120px;
  background: url("../../assets/media/components/characters/woman-1-type-3/head.svg") no-repeat center/contain;
  transform-origin: bottom;
  animation: rotateInLeft 7s 0.5s infinite;
}
@keyframes showCharacter{
  0%{
    transform: translateX(120%) scale(1.2);
  }
  100%{
    transform: translateX(-97%) scale(1.2);
  }
}
@keyframes rotateInRight{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(5deg);
  }
  100%{
    transform: rotate(0);
  }
}
@keyframes rotateInLeft{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0);
  }
}
</style>
