<template>
  <div
    v-if="animate"
    class="character"
    :class="{animate, hide}"
    @transitionend="onTransitionEnd"
  >
    <div class="body" />
    <div class="head" />
    <div class="pocket" />
    <div class="hand" />
  </div>
</template>

<script>
import mixin from './template/mixin';

export default {
  name: 'GirlType5',
  mixins: [mixin],
};
</script>

<style scoped>
@import "./template/base.css";
.character{
  top: 378px;
  right: 0;
  width: 270px;
  height: 500px;
  transform: scale(1.2) translateX(120%);
}
.character.animate{
  animation: showCharacter var(--transition-duration) var(--transition-delay) forwards;
}
.body{
  position: absolute;
  top: 0;
  left: 0;
  width: 168px;
  height: 497px;
  background: url("../../assets/media/components/characters/girl-type-5/body.svg") no-repeat center/contain;
}
.head{
  position: absolute;
  top: -98px;
  left: 40px;
  width: 154px;
  height: 123px;
  background: url("../../assets/media/components/characters/girl-type-5/head.svg") no-repeat center/contain;
  transform-origin: bottom;
  animation: rotateInRight 7s 0.5s infinite;
}
.hand{
  position: absolute;
  top: 52px;
  left: 120px;
  width: 40px;
  height: 204px;
  background: url("../../assets/media/components/characters/girl-type-5/hand.svg") no-repeat center/contain;
}
.pocket{
  position: absolute;
  top: 237px;
  left: 70px;
  width: 191px;
  height: 137px;
  background: url("../../assets/media/components/characters/girl-type-5/pocket.svg") no-repeat center/contain;
  transform-origin: top;
  animation: rotateInRight 7s 0.5s infinite;
}
@keyframes showCharacter{
  0%{
    transform: translateX(100%) scale(1.2);
  }
  100%{
    transform: translateX(-47%) scale(1.2);
  }
}
@keyframes rotateInRight{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(5deg);
  }
  100%{
    transform: rotate(0);
  }
}
@keyframes rotateInLeft{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0);
  }
}
</style>
