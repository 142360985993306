import { isTouchDevice } from '@/utils';

const PAGE_WIDTH = 1920;
const PAGE_HEIGHT = 944;
const RATIO_COEFICIENT = PAGE_WIDTH / PAGE_HEIGHT;

export default {
  data() {
    return {
      scaleValue: 1,
    };
  },
  created() {
    this.setPageScale();
    if (!isTouchDevice()) window.addEventListener('resize', this.setPageScale.bind(this));
    window.addEventListener('orientationchange', this.setPageScale.bind(this));
  },
  methods: {
    setPageScale() {
      this.setScaleValue();
      setTimeout(this.setScaleValue.bind(this), 150);
    },
    setScaleValue() {
      if (this.isBaseWidth()) {
        this.scaleValue = document.body.getBoundingClientRect().height / PAGE_HEIGHT;
      } else {
        this.scaleValue = document.body.getBoundingClientRect().width / PAGE_WIDTH;
      }
    },
    isBaseWidth() {
      return window.innerWidth / window.innerHeight >= RATIO_COEFICIENT;
    },
  },
};
