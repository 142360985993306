<template>
  <div
    v-if="animate"
    class="character"
    :class="{animate, hide}"
    @transitionend="onTransitionEnd"
  >
    <div class="body" />
    <div class="head" />
    <div class="bag" />
    <div class="hand" />
  </div>
</template>

<script>
import mixin from './template/mixin';

export default {
  name: 'Woman2Type5',
  mixins: [mixin],
};
</script>

<style scoped>
@import "./template/base.css";
.character{
  top: 217px;
  right: 0;
  width: 160px;
  height: 640px;
  transform: scale(1.2) translateX(120%);
}
.character.animate{
  animation: showCharacter var(--transition-duration) var(--transition-delay) forwards;
}
.body{
  position: absolute;
  top: 0;
  left: 0;
  width: 158px;
  height: 637px;
  background: url("../../assets/media/components/characters/woman-2-type-5/body.svg") no-repeat center/contain;
}
.hand{
  position: absolute;
  top: 231px;
  left: -3px;
  width: 145px;
  height: 102px;
  background: url("../../assets/media/components/characters/woman-2-type-5/hands.svg") no-repeat center/contain;
}
.bag{
  position: absolute;
  top: 321px;
  left: -17px;
  width: 110px;
  height: 142px;
  background: url("../../assets/media/components/characters/woman-2-type-5/bag.svg") no-repeat center/contain;
  transform-origin: top;
  animation: rotateInRight 7s 0.5s infinite;
}
@keyframes showCharacter{
  0%{
    transform: translateX(120%) scale(1.2);
  }
  100%{
    transform: translateX(-150%) scale(1.2);
  }
}
@keyframes rotateInRight{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(5deg);
  }
  100%{
    transform: rotate(0);
  }
}
@keyframes rotateInLeft{
  0%{
    transform: rotate(0);
  }
  50%{
    transform: rotate(-5deg);
  }
  100%{
    transform: rotate(0);
  }
}
</style>
