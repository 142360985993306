<template>
  <div
    class="popup popup-notification"
    :class="{opened}"
    @transitionend="hideContent"
  >
    <div
      v-show="shownContent"
      class="popup-content"
    >
      <p
        class="text"
        v-html="text"
      />
      <img
        src="../../assets/media/components/popup-notification/landscape-icon.png"
        class="landscape-mode-image"
        alt="landscape mode"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupNotification',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  emits: ['update:opened'],
  data() {
    return {
      shownContent: false,
    };
  },
  watch: {
    opened: {
      immediate: true,
      handler(value) {
        if (value) this.showContent();
      },
    },
  },
  methods: {
    closePopup() {
      this.$emit('update:opened', false);
    },
    showContent() {
      this.shownContent = true;
    },
    hideContent() {
      if (!this.opened) this.shownContent = false;
    },
  },
};
</script>

<style scoped>
.popup-notification{
  background: rgba(15, 66, 109, 0.9);
}
.popup-content{
  width: 1000px;
  padding: 100px;
}
.text{
  font-size: 40px;
  line-height: 44px;
  text-align: center;
}
.landscape-mode-image{
  display: flex;
  width: 300px;
  margin: 30px auto 0;
}
</style>
