import { createI18n } from 'vue-i18n';

const locale = process.env.VUE_APP_I18N_LOCALE || 'ru';

function loadLocaleMessages() {
  const data = {};
  const locales = require.context('.', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  locales
    .keys()
    .filter((key) => key.includes(`./${locale}`))
    .forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        data[matched[1]] = locales(key);
      }
    });

  return { [locale]: data };
}

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ru',
  messages: loadLocaleMessages(),
});
