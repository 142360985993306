<template>
  <form
    class="promo-code-form"
    action="/"
    method="POST"
  >
    <label
      class="label name"
      :class="{'invalid-value': invalidName && enableValidation}"
    >
      <span>{{ $t('components.promoCodeForm.labelName') }}</span>
      <span
        v-show="invalidName && enableValidation"
        class="invalid-message"
      >{{ $t('components.promoCodeForm.invalidNameMessage') }}</span>
      <input
        v-model="name"
        class="name-input input"
        type="text"
        :placeholder="$t('components.promoCodeForm.placeholderName')"
      >
    </label>
    <label
      class="label phone"
      :class="{'invalid-value': invalidPhone && enableValidation}"
    >
      <span>{{ $t('components.promoCodeForm.labelPhone') }}</span>
      <span
        v-show="invalidPhone && enableValidation"
        class="invalid-message"
      >{{ $t('components.promoCodeForm.invalidPhoneMessage') }}</span>
      <input
        ref="phone"
        v-model="phone"
        class="phone-input input"
        type="tel"
        :placeholder="$t('components.promoCodeForm.placeholderPhone')"
        maxlength="18"
        @paste="onPhoneInputPaste"
        @input="onPhoneInput"
      >
    </label>
    <label
      class="label email"
      :class="{'invalid-value': invalidEmail && enableValidation}"
    >
      <span>{{ $t('components.promoCodeForm.labelEmail') }}</span>
      <span
        v-show="invalidEmail && enableValidation"
        class="invalid-message"
      >{{ $t('components.promoCodeForm.invalidEmailMessage') }}</span>
      <input
        v-model="email"
        class="email-input input"
        type="email"
        :placeholder="$t('components.promoCodeForm.placeholderEmail')"
      >
    </label>
    <label
      class="checkbox-label"
    >
      <input
        v-model="confirmedPolicy"
        class="checkbox-input"
        type="checkbox"
      >
      <span
        :class="{ invalid: invalidConfirmedPolicy && enableValidation}"
        v-html="$t('components.promoCodeForm.confirmText', { link1: pdfPersonalInformation, link2: pdfPolicy, })"
      />
      <img
        v-show="confirmedPolicy"
        class="check-icon"
        src="../assets/media/common/check-icon.svg"
        alt="check-icon"
      >
    </label>
    <button
      class="main-button submit-button"
      type="submit"
      @click.prevent="submit"
    >
      {{
        $t('components.promoCodeForm.submitText')
      }}
    </button>
    <v-preload-hidden-images
      :images="[
        require('../assets/media/components/promo-code-form/send-button-hover.svg'),
        require('../assets/media/components/promo-code-form/send-button-active.svg'),
      ]"
    />
  </form>
</template>

<script>
import pdfPolicy from '@/assets/pdfs/Vertex_Duocold_Game_2021_policy.pdf';
import pdfPersonalInformation from '@/assets/pdfs/Vertex_Duocold_Game_personal_information.pdf';

/* eslint-disable no-useless-escape */
const EMAIL_REGEX = /^([a-z\d\._-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
const GET_PHONE_NUMBER_REGEX = /[^0-9+]/g;
const NOT_NUMBER_REGEX = /\D/g;
/* eslint-enable no-useless-escape */

export default {
  name: 'PromoCodeForm',
  data() {
    return {
      name: '',
      phone: '+7',
      email: '',
      confirmedPolicy: false,
      enableValidation: false,
      pdfPersonalInformation,
      pdfPolicy,
    };
  },
  computed: {
    invalidName() {
      return this.name.trim().replace(NOT_NUMBER_REGEX, '') > 0
        || !this.name;
    },
    invalidPhone() {
      return new Set(this.getPhoneNumber(this.phone).slice(2).split('')).size === 1
        || this.getPhoneNumber(this.phone).length < 12;
    },
    invalidEmail() {
      return !EMAIL_REGEX.test(this.email) || !this.email;
    },
    invalidConfirmedPolicy() {
      return !this.confirmedPolicy;
    },
  },
  methods: {
    onPhoneInput() {
      const number = this.getNumbers(this.phone);
      this.phone = this.getFormattedNumber(number);
    },
    getNumbers(value) {
      return value.trim().replace(NOT_NUMBER_REGEX, '');
    },
    getPhoneNumber(value) {
      return value.trim().replace(GET_PHONE_NUMBER_REGEX, '');
    },
    getFormattedNumber(number) {
      let formattedNumber = '+7';

      if (number.length > 1) {
        formattedNumber += `(${number.substring(1, 4)}`;
      }
      if (number.length >= 5) {
        formattedNumber += `) ${number.substring(4, 7)}`;
      }
      if (number.length >= 8) {
        formattedNumber += `-${number.substring(7, 9)}`;
      }
      if (number.length >= 10) {
        formattedNumber += `-${number.substring(9, 11)}`;
      }

      return formattedNumber;
    },
    onPhoneInputPaste(event) {
      const pasteData = event.clipboardData || window.clipboardData;
      const number = this.getNumbers(pasteData.getData('Text'));
      this.phone = this.getFormattedNumber(number);
    },
    submit() {
      if (this.invalidName || this.invalidPhone || this.invalidEmail || this.invalidConfirmedPolicy) {
        this.enableValidation = true;
        setTimeout(() => {
          this.enableValidation = false;
        }, 3000);
      } else {
        this.$emit('submitted', {
          name: this.name.trim(),
          phone: this.getPhoneNumber(this.phone),
          email: this.email.trim(),
          confirmedPolicy: this.confirmedPolicy,
        });
      }
    },
  },
};

</script>

<style scoped>
.promo-code-form{
  display: flex;
  width: 570px;
  height: 635px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../assets/media/components/promo-code-form/form-bg.svg") no-repeat center/contain;
}
.label{
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  width: 420px;
}
.label span{
  font: 12px/18px "Montserrat", Arial, sans-serif;
  margin-bottom: 5px;
  margin-left: 21px;
}
.label span.invalid-message{
  position: absolute;
  right: 0;
  color: #910303;
}
.label .input{
  outline: none;
  color: var(--primary-color);
  font: 700 18px/24px "Montserrat", Arial, sans-serif;
  border: 2px solid var(--secondary-color-5);
  border-radius: 50px;
  padding: 22px 20px;
}
.label .input::placeholder{
  color: var(--primary-color);
}
.label.invalid-value .input{
  border: 2px solid #910303;
}
.checkbox-label{
  position: relative;
  width: 420px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 15px;
  cursor: pointer;
}
.checkbox-input{
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-label span{
  font: 14px/16px "Montserrat", Arial, sans-serif;
}
.checkbox-label span::v-deep(a){
  font: inherit;
  color: inherit;
}
.checkbox-label span.invalid{
  color: #910303;
}
.check-icon{
  position: absolute;
  top: 5px;
  left: 4px;
  width: 18px;
  height: 14px;
}
.checkbox-label::before{
  flex-shrink: 0;
  content: "";
  width: 24px;
  height: 24px;
  margin-right: 15px;
  background: url("../assets/media/components/checkbox/checkbox.svg") no-repeat center/contain;
}
.submit-button{
  width: 320px;
  height: 98px;
  background: url("../assets/media/components/promo-code-form/send-button-defalut.svg") no-repeat center/contain;
}
.submit-button:hover{
  background: url("../assets/media/components/promo-code-form/send-button-hover.svg") no-repeat center/contain;
}
.submit-button:active{
  background: url("../assets/media/components/promo-code-form/send-button-active.svg") no-repeat center/contain;
}
</style>
